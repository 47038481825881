import { useEffect, useContext, useState } from "react";
import {  NavLink, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../context/AuthContext";
import SearchBar from "../Nav/SearchComponent";
import ModalCountrySelection from "../MoviesPage/ModalCountrySelection";
import "./TopNavBar.css";

const TopNavBar = (props) => {
	const {t} = useTranslation();

	const [isSearch, setIsSearch] = useState(false);
	const { user, setUser } = useContext(AuthContext);
	const [isModal, setModalDisplay] = useState(false);
	const [showLogOut, setLogOut] = useState(false);
	const currentUser = localStorage.getItem("currentUser");
	const profileIcon = props.profileIcon ? props.profileIcon : localStorage.getItem("profile_icon")
	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const navigate = useNavigate();

	useEffect(() => {
		setIsSearch(false)
	}, []);


	const toggleModal = () => {
		isModal ? setModalDisplay(false) : setModalDisplay(true);
	}

	const logOut = async () => {
		const resp = await axios_.get("/auth/logout");
		console.log(resp.message);

		if (resp.data.success) {
			setUser({});
			localStorage.clear();
			navigate("/login");
		}
	};

	const showNav=()=>{
		props.navToggle()
		setModalDisplay(false)
	}

	const toggleLogOut = () => {
		if (showLogOut) {
			setLogOut(false);
		} else {
			setLogOut(true);
		}

		setTimeout(() => {
			setLogOut(false);
		}, 5000);
	};

	let textSize;
	if (props.region) {
		textSize = props.region.length > 6 ? "13px" : "16px";
	}
	
	const getFirstLetterInitial = (username) => {
		if (typeof username === "string" && username.length > 0) {
			const initial = username.charAt(0).toUpperCase();
			return initial;
		} else {
			return "";
		}
	};

	const toggleSearchBar=()=>{
		setIsSearch(isSearch => !isSearch);
	}

	const navTabBar = isSearch ? "" : "4rem" 
	const windowWidth = $(window).width();
	const regionStyle = windowWidth <= 600 ? "mobile-region-style" : ""

	return (
		<>
			<nav className="navbar navbar-expand-lg bg-body-tertiary">
				<div className="container-fluid ">
					<Link onClick={showNav} to={'#'} className="d-none d-md-block d-sm-none" ><img className="navigation" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866913/AfroWatch/Group_3_rnlqdu.svg" alt=""/></Link>
					<a className="d-none d-md-block d-sm-none navbar-brand" href="/">
						<img
							className="navbarbrand-img"
							src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706482881/Afrowatch-Logo--Vertical-White-font_nh9xpc.svg"
							alt=""
						/>
					</a>
					<div className="d-block">
						{props.region ? (
							<span className={`ml-1 ${regionStyle}`}>
								<Link style={{ color: "white", fontSize: textSize, }} to="#" onClick={toggleModal} >
									{t("region")}: {props.region}
								</Link>
							</span>
						) : (
							<span>{t("noRegionSelectedYet")}</span>
						)}
					</div>

					{isModal ? (<ModalCountrySelection closed={toggleModal} />) : null}
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul
							className="navbar-nav d-flex m-auto"
							style={{
								background: "#1F0F25",
								padding: "1rem",
								border: "2px solid #3B193A",
								borderRadius: "15px",
								position: "relative",
								right: navTabBar,
								top: '.2rem',
							}}
						>
							<li className="nav-item">
								<NavLink exact to={`/profile/${currentUser}/watch/`} activeClassName="active" className="nav-link">{t("home")}</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to="/my-list" activeClassName="active" className="nav-link">{t("myList")}</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to="/new-arrivals" activeClassName="active" className="nav-link">{t("newArrivals")}</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to="/downloads" activeClassName="active" className="nav-link">{t("downloads")}</NavLink>
							</li>
							<li className="nav-item">
								<NavLink to="/afros" activeClassName="active" className="nav-link">AfClips</NavLink>
							</li>
						</ul>
						<span className="navbar-text pt-3 d-flex justify-content-end">
							<div className="mr-5 mt-2">
								{isSearch ? <SearchBar onClose={toggleSearchBar} /> : 
									<SearchIcon
										color="disabled"
										variant="h2"
										style={{ cursor: "pointer", fontSize: "2rem", fontWeight: 300, color: 'white' }}
										onClick={toggleSearchBar}
									/>
								}
							</div>
							<div>
								{
									profileIcon ?
										<Link onMouseOver={toggleLogOut} className="pr-md-3 text-decoration-none" to={`/profile/${currentUser}`} >
											<img src={profileIcon} alt="profile_icon" style={{ width:'54px', height:'54px', borderRadius:'10px'}} />
										</Link>
										:
										<Link to={`/profile/${currentUser}`}>
											<Avatar sx={{ bgcolor: grey[500] }} variant="square">
												{getFirstLetterInitial(props.profile)}
											</Avatar>
										</Link>
								}
							</div>
						</span>

						{showLogOut ?
							<div className='logout'>
								<span style={{color:'#D02A3A',position:'absolute',right: '63px',top: '65px'}}>&#x25B2;</span>
								<button type="button" style={{position:'absolute', top:'80px', right:'28px', width:'90px'}} className="btn btn-danger" onClick={logOut}>Log out</button>
							</div>
							: null} 
					</div>
					
					<div className="d-flex align-baseline justify-content-between w-100 d-block d-md-none">
						<a className="navbar-brand" href="/">
							<img
								className="navbarbrand-img w-auto"
								src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706482881/Afrowatch-Logo--Vertical-White-font_nh9xpc.svg"
								alt=""
							/>
						</a>
						<span className="navbar-text pt-3 d-flex justify-content-end">
							<div className="mr-3">
								{isSearch ? <SearchBar onClose={toggleSearchBar} /> : 
									<SearchIcon
										color="disabled"
										variant="h2"
										style={{ cursor: "pointer", fontSize: "2rem", fontWeight: 300 }}
										onClick={toggleSearchBar}
									/>
								}
							</div>
							<div>
								{
									profileIcon ?
										<Link onMouseOver={toggleLogOut} className="pr-md-3 text-decoration-none" to={`/profile/${currentUser}`} >
											<img src={profileIcon} alt="profile_icon" className="profile-img" />
										</Link>
										:
										<Link to={`/profile/${currentUser}`}>
											<Avatar
												sx={{ bgcolor: grey[500] }}
												variant="square"
											>
												{getFirstLetterInitial(props.profile)}
											</Avatar>
										</Link>
								}
							</div>
						</span>

						{showLogOut ?
							<div className='logout'>
								<span style={{color:'#D02A3A',position:'absolute',right: '63px',top: '65px'}}>&#x25B2;</span>
								<button type="button" style={{position:'absolute', top:'80px', right:'28px', width:'90px'}} className="btn btn-danger" onClick={logOut}>Log out</button>
							</div>
							: null} 
					</div>
				</div>
			</nav>
		</>
	);
};

export default TopNavBar;
