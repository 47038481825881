const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
	minimumIntegerDigits: 2,
})

export function formatVideoTime(time) {
	const seconds = Math.floor(time % 60)
	const minutes = Math.floor(time / 60) % 60
	const hours = Math.floor(time / 3600)
	if (hours === 0) {
		return `${minutes}:${leadingZeroFormatter.format(seconds)}`
	} else {
		return `${hours}:${leadingZeroFormatter.format(
			minutes
		)}:${leadingZeroFormatter.format(seconds)}`
	}
}

export const TimeDifferenceCalc = (timeInterval) => {
	let _previousTime = Date.now() // time in miliseconds
	return {
		setPreviousTime(time) {
			_previousTime = time
		},

		checkIfAboveThresholdDifference(currentTime = Date.now()) {
			return (currentTime - _previousTime) > timeInterval
		}
	}
}


export function resolveSourceTypeFromVideoType(videoType) {
	switch (videoType) {
	case "mp4":
		return "video/mp4"
	case "m3u8":
	case "hls":
		return 'application/x-mpegURL'
	default:
		return 'application/x-mpegURL'
	}
}
