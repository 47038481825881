import React, { useState, useEffect} from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import NavBar from '../components/Nav/NavBar'
import ThemeContextProvider from '../context/ThemeContext';
import { AuthContext } from '../context/AuthContext';
import Home from './Home/Home'
import SignUp from '../components/Authentication/SignUp'
import LogIn from '../components/Authentication/LogIn'
import Verify from '../components/Authentication/Verify';
import ForgotPassword from './Authentication/ForgotPassword';
import CheckEmail from './Authentication/CheckEmail';
import ResetPassword from './Authentication/ResetPassword';
import SplashScreen from '../components/SplashScreen/SplashPage'
import WhosWatching from '../components/WhosWatching/WhosWatching';
import MoviesPage from '../components/MoviesPage/MoviesPage'
import FilterPage from './Filter/FilterContent';
import NollyWoodPage from '../components/MoviesPage/NollyWoodPage';
import MovieDescriptionPage from '../components/MovieDescription/MovieDescPage';
import MediaPlayerPage from '../components/MediaPlayer/MediaPlayerPage';
import AfrimePage from '../components/Afrime/AfrimePage';
import AfrimeSelection from '../components/Afrime/AfrimeSelection'
import EducationPage from './Afrime/EducationPage';
import PricingPlanPage from '../components/Pricing/PricingPlan';
import StartYourPlan from '../components/Pricing/StartYourPlan';
import ProfilePage from '../components/Profile/Profile';
import Search from './Search/Search';
import ListPage from './ListPage';
import NewArrivalsPage from './NewArrivals';
import DownloadPage from './DownloadPage';
import Shorts from './Shorts/Shorts'
import NotFound from './NotFound/NotFound';
import "../i18n/i18n.js";
import './App.css'

const App = () => {
	const[user, setUser] = useState('')

	//protected routes
	const requireAuth =(nextState, replace)=> {
		if (user === '') {
			replace('/login')
		}
	}

	return ( 
		<BrowserRouter>
			<React.Fragment>
				<div className= "App">
					<AuthContext.Provider value={{user, setUser}}>
						<Routes>
							{/* <NavBar routeBg={setPageBg}/> */}
							<Route path="/" element={<Home />} /> 
							<Route path="/splash" element={<SplashScreen />} onEnter={requireAuth} />
							<Route path="/notify" element={<Home />} />
							<Route path="/login" element={<LogIn />} />
							<Route path="/signup" element={<SignUp />} />
							<Route path="/forgot-password" element={<ForgotPassword />} />
							<Route path="/check-email" element={<CheckEmail />} />
							<Route path="/reset-password" element={<ResetPassword />} />
							<Route path="/verify" element={<Verify />} />
							<Route path="/whos-watching" element={<WhosWatching />} onEnter={requireAuth} />

							<Route exact path='/profile/:userid/watch' element={<MoviesPage/>}/>  
							<Route exact path='/profile/:userid/watch/Nigeria' element={<NollyWoodPage/>}/>
							<Route exact path='/profile/:userid/watch/:filter' element={<FilterPage/>}/>
							<Route exact path='/profile/:userid/search' element={<Search/>}/>
							<Route exact path='/my-list' element={<ListPage/>}/>
							<Route exact path='/new-arrivals' element={<NewArrivalsPage/>}/>
							<Route exact path='/downloads' element={<DownloadPage/>}/>
							<Route exact path='/afros' element={<Shorts/>}/>

							<Route path='/profile/:userid/watch/afrime' element={ <ThemeContextProvider><AfrimeSelection/></ThemeContextProvider>}/> 
							<Route path='/profile/:userid/afrime/education' element={<EducationPage/>}/> 
							<Route exact path='/profile/:userid' element={<ThemeContextProvider> <ProfilePage/> </ThemeContextProvider>}/> 
							<Route path='/profile/:userid/edit' element={<ThemeContextProvider> <ProfilePage/> </ThemeContextProvider>}/> 
                            
							<Route exact path='/watch/:contentid' element={<MovieDescriptionPage/>}/> 
							<Route path='/watch/:contentid/play' element={<MediaPlayerPage/>}/>
							<Route path='/pricing-plan' element={<PricingPlanPage/>}/>
							<Route path='/pricing/start-your-plan/:planid' element={<StartYourPlan/>}/>
							{/* 404 page doesnt exists */}
							<Route path='*' element={<NotFound/>} /> 
						</Routes>
					</AuthContext.Provider>
				</div>
			</React.Fragment>
		</BrowserRouter>  
	);
}

export default App;