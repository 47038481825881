import {useEffect} from "react";
import {setProfileStreamPlayBackData, uploadUserPlayBackData, getContentPlayBackDataFromDB} from "./controls/_playback";
import {Chalk} from 'chalk'
import {PLAYBACK_TRACKING_OPTS} from "../constants";
import {formatVideoTime, TimeDifferenceCalc} from "./controls/_utils";

// NOTES:
// 2. The timeupdated event fires too frequently for the purpose of memorizing progress
//    hence down-sampling the event is desired
// 3. Run the following effect to control uploading the playback data to the backend,
//    so we can always have a store of it, in case a user uses multiple devices

const PlayBackTracker = (props) => {
	const userId = localStorage.getItem('currentUser')
	const profileId = localStorage.getItem("selected_prof_id");

	const chalk = new Chalk()

	useEffect(() => {
		console.log(userId, profileId)
		try{
			getContentPlayBackDataFromDB(userId, profileId, props.streamData.stream.content_id)
		}catch(err){
			console.log(`NO CONTENT ID FOUND FOR PLAYBACK`)
			console.log(err)
		}

		const player = props.player
		if (player && player.isReady_) {

			const updateTimeCalculator = TimeDifferenceCalc(PLAYBACK_TRACKING_OPTS.playbackDataUpdateInterval)
			const uploadTimeCalculator = TimeDifferenceCalc(PLAYBACK_TRACKING_OPTS.playbackDataUploadInterval)

			player.on("timeupdate", function () {
				const now = Date.now()
				if (updateTimeCalculator.checkIfAboveThresholdDifference(now)) {
					updateTimeCalculator.setPreviousTime(now)
					player.trigger("setPlaybackDataInLocalStorage")
				}
				if (uploadTimeCalculator.checkIfAboveThresholdDifference(now)) {
					uploadTimeCalculator.setPreviousTime(now)
					player.trigger("uploadPlaybackToDb")
				}
			})

			player.on("setPlaybackDataInLocalStorage",  () => {
				let contentId = props.streamData.stream.content_id
				let currentPlayerTime = player.currentTime()
				const totalPlayerTime = player.duration();
				
				setProfileStreamPlayBackData(profileId, contentId, currentPlayerTime, totalPlayerTime)
				console.log(`Stored playback for stream ${contentId}, playback progress at ${formatVideoTime(currentPlayerTime)} of ${formatVideoTime(player.duration())}`)
			})

			player.on("uploadPlaybackToDb", async () => {
				if(props.streamData.stream.content_id){
					const isSuccessful = await uploadUserPlayBackData(userId, profileId, props.streamData.stream.content_id)
					console.log(isSuccessful ? chalk.green("Uploaded playback data successfully.") : chalk.red('playback data failed to upload'))
				}
			})
		}
	}, [])

	return <></>
}
export default PlayBackTracker;