import React, {useState, useEffect} from 'react';
import './CurrentPlan.css';
import { useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import ExplorePlans from './ExplorePlans';

const CurrentPlan=()=>{
	const [plan, setPlan] = useState({})
	const [user, setUser] = useState({})
	const [subscription, setSub] = useState()
	const [explore, setExplore] = useState(false)
	const [cards, setCards] = useState({})
	const [cancelMessage, setMessage] = useState("")
	const [snackOpen, setSnackOpen] = useState(false)

	const currentUser = localStorage.getItem("currentUser");
	const currentProfile = localStorage.getItem("profile_name");
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const navigate = useNavigate()

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`)
		const {data} =  resp.data;
		if(resp.data.success){
			console.log(data)
			return data
		}else{
			console.log(resp.data.message)
		}
	}

	const getPlan = async ()=>{
		const user = await getUser()
		setUser(user)
		if (user.currentPlan){
			const res = await axios_.get(`/plan/${user.currentPlan['id']}`)
			if(res.status === 200){
				setPlan(res.data.plan)
			}else{
				console.log('The plan does not exists')
			}

			const resp_two = await axios_.get(`/user/stripe/${currentUser}/${user.currentPlan.subscriptionId}`)
			if(resp_two.data.status === 200){
				setSub(resp_two.data.sub)
			}else{
				console.log(resp_two.data.message)
			}
		}else{
			console.log('no plan yet')
			setPlan({
				subscription_name: 'no plan',
				price: 0
			})
		}
	}

	const getUserCards = async()=>{
		if(user.length > 0){
			console.log(user)
		}

		try{
			if(user.stripeCustomerId){
				const res = await axios_.get(`/${user.stripeCustomerId}/stripe/cards`)
				if(res.status === 200){
					console.log(res.data)
					setCards(res.data)
				}else{
					console.log('didnt get cards')
				}
			}
		}catch(e){
			console.log('The stripe customer might not exists or is wrong')
		}
	}

	useEffect(()=>{
		getUser()
		getPlan()
	},[])

	useEffect(()=>{
		getUserCards()
	},[user])

	const toggleExplore=()=>{
		if(explore){
			setExplore(false)
		}else{
			setExplore(true)
		}
	}

	const cancelSub= async(e)=>{
		try{
			const res = await axios_.post(`/cancel-subscription/${currentUser}`, {sub_id: user.currentPlan.subscriptionId})
			if(res.data.status === 200){
				setMessage(res.data.message)
				setSub(res.data.sub)
				setSnackOpen(true)
				setTimeout(()=>{
					localStorage.removeItem('currentUser')
					navigate('/login')
				}, 1000)
			}
		}catch(err){
			console.log(err)
			setMessage('Error. no plan yet')
			setSnackOpen(true)
		}
	}

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackOpen(false);
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);


	return(
		<div style={{border:'1px solid #C92941', width:'100%', borderRadius:'10px', background:'#250F24', height:'500px'}} className="card mt-5">
			<div className="col-md-12">
				{explore ? <ExplorePlans userId={user._id} user={user} /> :
					<>
						<h3 className="pt-3 text-current-plan">Subscription</h3>
						<div className="d-flex">
							<div className="light-white-water"></div> <div className="light-white-water-two ml-md-2"></div>
						</div>

						<p className="mt-5 sub-labels">Transaction ID- {user && user.currentPlan ? user.currentPlan.subscriptionId.split('_')[1] : null} </p>
						
						<div className="d-flex">
							<ul style={{paddingLeft:'0px'}}>
								<p className="mt-3 sub-labels">Current plan</p>
								<p style={{fontWeight: 'bold',fontSize: '14px',lineHeight: '5px',color: '#FFFFFF'}}>{`${plan && plan.subscription_tier  ? plan.subscription_tier : 'no plan'} for $ ${plan && plan.price ? plan.price : 0}/month`}</p>
							</ul>

							<ul>
								<p className="sub-labels mt-3">Next Due date:</p>
								<p style={{color:'#FFFFFF', fontSize: '14px',lineHeight: '5px'}}>{subscription ? new Date(subscription.current_period_end * 1000).toDateString() : ""}</p>
							</ul>
						</div>

						{user && user.profiles && user.profiles.length > 0 ?
							(user.profiles[0].profile_name === currentProfile ?
								<div>
									<button onClick={cancelSub} className='cancel-btn py-1 px-3'>Cancel subscription</button>
								</div> : null
							) 
							: null
						}
					</> 
				}
				
	
				{user && user.profiles && user.profiles.length > 0 ?
					(user.profiles[0].profile_name === currentProfile ?
						<div className="row mt-5">
							<div className="col-md-10">
								<button className="back-prof-btn py-1 px-3 justify-content-center mt-5" onClick={()=> setExplore(false)}>Back</button>
								<button className="edit-prof-btn py-1 px-3 justify-content-center mt-5" onClick={toggleExplore}>Explore Plans</button>
							</div>
						</div>
						: null
					) 
					: null
				}

				{snackOpen ? <Snackbar open={snackOpen} autoHideDuration={8000} onClose={handleSnackBarClose} message={cancelMessage} action={action} /> : null}
			</div>
		</div>
	)
}

export default CurrentPlan;