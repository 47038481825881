import { Link } from "react-router-dom";

const SearchResult = ({ contentName, contentId, description, actors, header_image, length }) =>{
	return(
		<li className="py-2 search-item" key={contentId}>
			{/* Thinking to either make another req here or just pass the remaining attributes to populate description page. 
				This is because of the description page uses state from the last location. 
			 	However that would mean returning the other attributes on search from the backend not just `id` and `contentName` */}
			<Link 
				style={{color: 'white', textDecoration:'none'}} 
				to={`/watch/${contentId}`}
				state={{
					content_id: contentId,
					content_name: contentName,
					description,
					actors,
					header_image,
					length
				}}>
				{contentName}
			</Link>
		</li>
	)
    
}

export default SearchResult;