import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link, useNavigate } from 'react-router-dom';
import {} from 'react-bootstrap';
import NavBar from '../Nav/NavBarNollyWoodPage';
import './NollyWoodPage.css';

const ModalNollyWood =()=>{
	const navigate = useNavigate()
	//explicitly set region as new component 
	localStorage.setItem('region', 'Nigeria')
	const region = localStorage.getItem("region");

	const [isModal, setModalDisplay] = useState(false);
	const [prevState, setState] = useState(
		[
			{
				id: uuidv4(),
				nollyWoodType: 'Classics',
				link:'/'
			},

			{
				id:uuidv4(),
				nollyWoodType: 'New Nollywood',
				link:'https://www.google.com'
			}
		]);

	const[initialThemeState, setThemeState] = useState(
		{
			categoryCardBackground: '#C92941'
		}
	)

	useEffect(()=>{
		if(!window.location.hash) {
			window.location = window.location + '#loaded';
			window.location.reload();
		}
	},[])

	const toggleModal=()=>{
		isModal ? setModalDisplay(false):  setModalDisplay(true);
	}

	const getClassics=()=>{
        
	}

	return(
		<div className="bg-sec-nollywood-page">
			<NavBar region={region}/>

			{isModal ? <ModalCountrySelection closed={toggleModal} /> : null}
			<div className="container">
				<div className="row">
                   
				</div>

				<div className="row justify-content-center mt-md-5">
					<div className="col-md-6">
						<Link className="" onClick={(e)=>{}} style={{textDecoration: 'none', position:'relative', top:'156px'}} to={prevState[0].link}>
							<div className="card country-card" style={{background: initialThemeState.categoryCardBackground, backgroundSize:'contain'}}>
								<div className="card-body">
									<p className="text-center modal-text py-md-4">{prevState[0].nollyWoodType}</p>
								</div>  
							</div>
						</Link>
					</div>

					<div className="col-md-6">
						<Link onClick={(e)=>{}} style={{textDecoration: 'none', position:'relative', top:'156px'}} to={{ pathname: "https://google.com" }} target="_blank">
							<div className="card country-card" style={{background: initialThemeState.categoryCardBackground, backgroundSize:'contain'}}>
								<div className="card-body">
									<p className="text-center modal-text py-md-4">{prevState[1].nollyWoodType}</p>
								</div>  
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)

}

export default ModalNollyWood;