import React,{useState,useEffect, useContext} from 'react';
import{Link, useNavigate} from 'react-router-dom'
import {Slide, Paper, CircularProgress} from '@mui/material';
import {Alert} from 'react-bootstrap';
import ReactGA from 'react-ga4'
import Toast from '../Notification/Toast';
import axios from 'axios';
import './LogIn.css'
import { AuthContext } from '../../context/AuthContext';
import Footer from '../Footer/Footer';

const LogInPage=()=>{
	const error ="Your password must contain at least 8 characters\na number \na special character !@#$%^&*\nan upper case letter and a lower case letter"
	const [initialFormState, setFormState] = useState({email:"", password:"", error:error, isValidInput:true, isValidUser:true, isAlert:false, invalidUserError:'', currentPlan:null});
	const [initialAnimation, setAnimation] = useState({slideChecked:true, isProgressShown:false})
	const [isAlert, setAlert] = useState({message:'', alert:false})

	const {user, setUser} = useContext(AuthContext)

	const navigate = useNavigate();
	const regex = /^(?=.*[0-9])(?=.*[!@#$%^_&*])[a-zA-Z0-9!@#$%^_&*]{8,16}/;
	const emailRegex = /(?=.*[@])[@]/;
	const exists = initialFormState.password.match(regex);
	const checkEmailValidity = initialFormState.email.match(emailRegex);

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const windowWidth = $(window).width();

	const onFormSubmit= async (e)=>{
		e.preventDefault();
		console.log(exists); //returns an array of value or null
		setAnimation({...initialAnimation, isProgressShown:true})

		if(exists === null ){
			console.log("password invalid")
			setAnimation({...initialAnimation, isProgressShown:false});
			setFormState({...initialFormState, isValidInput:false});

		}else{
			setFormState({...initialFormState, isValidInput:true});

			//POST REQUEST
			console.log("password & email are valid inputs")

			const user = {username: initialFormState.email, password:initialFormState.password};

			try{
				const resp = await axios_.post('/auth/login', user, { params: { userid:localStorage.getItem('now') }} )
				console.log(resp.data);

				if(resp.data.success){
					const {data} = resp.data
					setUser({username:data.username, userId: data._id, isLoggedIn:true})
                    
					// store the user in localStorage
					localStorage.setItem('currentUser', data._id)

					setAnimation({...initialAnimation, isProgressShown:false});
					setFormState({...initialFormState, isValidUser:true, currentPlan:data.currentPlan})
                    
					navigate({pathname:'/splash', state:{isLoggedIn:true, userId:resp.data._id}});
				}else{
					console.log(resp.data.message)
					setAlert({...isAlert, message:resp.data.message, alert:true})
					setAnimation({...initialAnimation, isProgressShown:false});
				}
                
			}catch(e){
				console.log(e)

				console.log("invalid user")
				setAnimation({...initialAnimation, isProgressShown:true})

				setTimeout(()=>{setAnimation({...initialAnimation, isProgressShown:false})}, 3000)
				setFormState({...initialFormState,invalidUserError:"No such user exists", isValidUser:false})
				navigate('/login');
			}

		}

		ReactGA.event({
			category: 'Authentication/LogIn',
			action: 'Clicked Log In button'
		});

	}

	useEffect(()=>{
		const userId = localStorage.getItem("currentUser");
		console.log(userId)

		if(userId !== null && userId.length > 0) {
			setUser({userId: userId, isUserLoggedIn:true})
			console.log('logged in')
			navigate({pathname:'/splash', state:{isLoggedIn:true}});
		}else{
			console.log('not logged in')
		}

	},[])

	const socialLogIn =(e)=>{
		e.preventDefault()
		alert('Coming soon!')
	}

	return(
		<div className="login-bg">
			<div className="container">
				<div className="row justify-content-center">
					{isAlert.alert ? <Toast message={isAlert.message} paySuccess={isAlert.alert}/> : null}

					<div className="col-md-6">
						<Slide direction="down" in={initialAnimation.slideChecked} style={(initialAnimation.slideChecked ? { timeout: 2500 } : {})} mountOnEnter unmountOnExit>
							<img className="login-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
						</Slide>
                    
						<p className='login-welcome-text pt-5'>Welcome </p>
						<form onSubmit={onFormSubmit} action="" method="post">
							<div className="form-input">
								<input id="email" name="email" type="email" className="form-control log-in-input my-5 py-2" value={initialFormState.email} onChange={(e)=>{setFormState({...initialFormState, email:e.target.value})}} placeholder="Email Address" required/>
								<input id="password" name="password" type="password" className="form-control log-in-input my-5 py-2" value={initialFormState.password} onChange={(e)=>{setFormState({...initialFormState, password:e.target.value})}}  placeholder="Password" required/>
                                
                                
								<button button className="sign-up-btn py-1 px-5" type="submit" >Login</button>

								<Link className='forgot-password-link' to="/forgot-password">Forgot password ?</Link>
								<div className="d-flex py-5">
									<a onClick={socialLogIn} href="#"><img className="px-2 loginpage_social" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618765128/AfroWatch/Vector_2_yrfbkq.svg" alt=""/></a>
									<a onClick={socialLogIn} href="#"><img className="px-2 loginpage_social" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618765128/AfroWatch/instagram_logo_frufem.svg" alt="" srcset=""/></a>
								</div>

								<p className='text-center'>Haven&#39;t signed up yet ?</p>

								<Link className="log-in-btn py-1" to="/signup">Sign Up</Link>
                                
								{!initialFormState.isValidInput ? 
									(<><p className='mt-5'>{initialFormState.error}</p>
										<Toast message={initialFormState.error} paySuccess={true}/>
									</>)
									: null
								}


								{initialAnimation.isProgressShown ? <CircularProgress size={25} sx={{position:'absolute', bottom:'40px', right:'270px', color:'#15C48E'}} /> : null}

								{!initialFormState.isValidUser ? 
									<Slide direction="left" in={true} mountOnEnter unmountOnExit>
										<div style={{bottom:'450px', left:'470px', width:'40%', color:'white', position:'absolute', borderRadius:'5px'}} className="btn-danger text-center py-2">{initialFormState.invalidUserError}</div>
									</Slide>  
									: null}
							</div>
						</form>

					</div>
				</div>
			</div>
			{windowWidth > 600 ? <Footer/> : null}
		</div>
	)
}

export default LogInPage;