import React, {useEffect,useState, useContext} from 'react';
import {ThemeContext} from '../../context/ThemeContext';

const Settings=()=>{
	const[isInitialToggle, setToggle] = useState(false);

	const {isGlobalLightTheme,lightTheme, darkTheme, changeGlobalTheme} = useContext(ThemeContext)
	const theme = isGlobalLightTheme ? lightTheme : darkTheme;


	const toggleSwitch=()=>{
		let toggle = !isInitialToggle ? setToggle(true): setToggle(false);
		if(toggle){
			console.log("check set to true")
			//download over wifi or data
		}else{
			console.log("set the check to false")
		}
	}
   
	//CURRENTLY ONLY AFFECT STYLE IN SETTINGS
	let settingStyle={
		background: theme.background,
		color:theme.text,
		transition: '1s ease-in-out'
	}

	return(
		<div className="container"> 
			<div style={settingStyle} className="mt-5 px-3 card settings-card py-4">
				<div className="row">
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Group_1_tsdu4n.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<h6 style={settingStyle} className="settings-text">App Version</h6>
						<p style={settingStyle} className="">V1.0</p>
					</div>
				</div>
			</div>

            
			<div className="mt-4 px-3 card settings-card py-3">
				<div className="row">
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Group_2_byyliv.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<h6 className="settings-text">Theme Option</h6>
						<input checked={isGlobalLightTheme} type="radio" name="theme" id="light" value="lightTheme" onChange={changeGlobalTheme} />
						<label className="settings-text-theme mx-4 mx-md-5" htmlFor="light">Light</label>

						<input type="radio" name="theme" id="dark" value="darkTheme" onChange={changeGlobalTheme}/>
						<label className="settings-text-theme ml-4 mx-md-5"  htmlFor="dark">Dark</label>
						<p></p>
					</div>
				</div>
			</div>


           
			<div className="mt-4 px-3 card settings-card py-3">
				<div className="row">
					<div className="col-md-3">
						<img className='settings_icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621111663/AfroWatch/Vector_qfs9gp.svg" alt=""/>
					</div>

					<div className="col-md-9">
						<h6 className="settings-text">Download over wifi or data</h6>
						<label className="switch">
							<input checked={isInitialToggle} type="checkbox" onChange={(e)=>{!isInitialToggle ? setToggle(true): setToggle(false)}} />
							<span className="slider round"></span>
						</label>
						<p></p>
						<p></p>
					</div>
				</div>
			</div>
               
		</div>
	)

}

export default Settings;