import { useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';
import { axiosInstance } from '../../apiClient';
import './NavBarSearch.css';
import SearchResult from './SearchResultDisplay';

const Search=(props)=>{
	const [searchParams, setSearchParams] = useSearchParams('')
	const [searchResult, setSearchResult] = useState()

	const searchTerm = searchParams.get('q') || ''

	useEffect(()=>{
	},[])

	const search = async (e)=>{
		const searchVal = e.target.value
		if(searchVal){
			setSearchParams({q: searchVal})

			const res = await axiosInstance.get(`/search/${localStorage.getItem('region')}?q=${searchVal}`)
			if(res.data.status === 200){
				setSearchResult(res.data.content)
			}
		}else{
			setSearchParams({ })
		}
		
	}

	//Search V2: Elastic
	const onFormSubmit= async (e)=>{
		const res = await axiosInstance.get(`/search/?q=${searchTerm}`)
		if(res.data.status === 200){
			setSearchResult(res.data.content)
		}
	}

	const styleOne = {
		position: 'relative',
		bottom: '12px'
	}

	const searchStyle = !window.location.pathname.includes('pricing-plan') ? styleOne : {}

	return(
		<>
			<div style={searchStyle}>
				<form onSubmit={onFormSubmit} action="" method="post">
					<input className="search border" type="search" name="search" id="" placeholder="Search content" value={searchTerm} onChange={search} style={{color: 'white'}}/>
					{!window.location.pathname.includes('pricing-plan') ? <button type='submit' onClick={props.onClose} style={{ background: 'none', border: '0px', color: 'white'}}>x</button> : null }
				</form>
			</div>

			{searchTerm ? <div className='search-result'>
				{
					searchResult ? 
						(searchResult[searchTerm] && searchResult[searchTerm].map((item, pos) => (
							<SearchResult key={item.contentId} 
								contentId={item.contentId} 
								contentName={item.contentName} 
								description={item.description}
								actors={item.actors}
								header_image={item.header_image}
								length={item.length}/>
						))) : null
				}
			</div> : null }
		</>
	)
}

export default Search;