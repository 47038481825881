import React, {useEffect, useState} from 'react';
import { useNavigate, useSearchParams} from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import {Slide} from '@mui/material'

import axios from 'axios';

const VerifyPage=()=>{
	const [verifyState, setVerifyState] = useState({isVerified:false, message:"Not verified"})
	const  [isAlert ,setAlert] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
    
	const token = searchParams.get("token")
	const navigate = useNavigate()

	const verify =async ()=>{
		try{
			const resp = await axios_.post('/auth/verify', {token: token}, { params: {token: token }} )
			const {data} = resp.data;

			if(resp.data.status === 200){
				console.log(resp.data);
				setVerifyState({...verifyState, isVerified:resp.data.user.isVerified, message:resp.data.message})
				setAlert(verifyState.isVerified)
				setTimeout(()=>{navigate('/login')} ,2000)
			}

		}catch(err){
			console.log(err);
		}
	}

	useEffect(()=>{
		verify();
	})


	return(
		<div className="container">
			<div className="alert-row row justify-content-end">
				<div className="col-md-4">
					{verifyState.isVerified && isAlert ? 
						<Slide direction="left" in={true} mountOnEnter unmountOnExit>
							<Alert variant="success" onClose={()=> setAlert(false)} dismissible>{`${verifyState.message}`}</Alert> 
						</Slide> : null}   
				</div>
			</div> 

			<div className="row justify-content-center mt-5">
				<div className="col-md-6">
					<img className="login-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
					<div>{verifyState.message}</div>
				</div>
			</div>
		</div>
	)

}

export default VerifyPage;