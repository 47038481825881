import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FilterPage from '../Filter/FilterContent';
import {BottomNavigation, BottomNavigationAction} from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import Footer from '../Footer/Footer';

const Search=()=> {
	const navigate = useNavigate();

	const [navValue, setValue] = useState(3);
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});

	const currentUser = localStorage.getItem("currentUser");

	useEffect(()=>{
		if($(window).width() <= 600){
			setFooter({...isFooter, bottomNavActive:true, footerActive:false})
		}
	},[])  

	useEffect(()=>{
		switch (navValue) {
		case 0:
		case 1:
			navigate(`/profile/${currentUser}/watch`);
			break;
		case 2:
			navigate(`/profile/${currentUser}`);
			break;
		case 3:
			navigate(`/profile/${currentUser}/search`);
			break;
		default:
			navigate('/');
		}
	},[navValue])

	return (
		<div>
			<FilterPage />
			<BottomNavigation value={navValue} onChange={(event, newValue) => {setValue(newValue)}} className="bottom-nav" >
				<BottomNavigationAction label="" icon={<HomeOutlinedIcon fontSize='large'/>} />
				<BottomNavigationAction label="" icon={<OndemandVideoOutlinedIcon fontSize='large'/>}/>
				<BottomNavigationAction label="" icon={<AccountCircleOutlinedIcon fontSize='large'/>} className="bottom-nav-icon-img"/>
				<BottomNavigationAction label="" icon={<SearchOutlinedIcon fontSize='large'/>}/>
			</BottomNavigation>
		</div>
	);
}

export default Search;
