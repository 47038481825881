import React from "react";
import $ from 'jquery';

export const CustomPrevArrow = (props) => {
	const { className, onClick } = props;

	const windowWidth = $(window).width();

	return (
		<div
			className={className}
			onClick={onClick}
			style={{
				backgroundColor: "purple",
				borderRadius: "5px",
				padding: '5px',
				cursor: 'pointer',
				position: 'absolute',
				top: '-10%',
				left: windowWidth > 600 ? '86%' : '76%',
				transform: 'translateY(-50%)',
				width: '32px',
				height: '32px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}>
			<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
				<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" fill="#fff" />
				<path fill="none" d="M0 0h24v24H0z" />
			</svg>
		</div>
	);
};