import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import { ProgressBar } from "react-bootstrap";
import $ from 'jquery';
import TopNavBar from '../Nav/TopNavBarV2';
import NavDrawer from '../Nav/NavDrawer';
import MovieCard from '../MoviesPage/MovieCardComponent';
import { useTranslation } from "react-i18next";
import axios from 'axios'
import Toast from '../Notification/Toast';
import MovieSliderSimilarMovies from '../Slider/MovieSliderSimilarMovies';
import Footer from '../Footer/Footer';
import BottomNav from '../Nav/BottomNav';
import '../MoviesPage/MoviesPage.css'
import './MovieDesc.css'

const MovieDescPage=(props)=>{
	const [isNavNotShowing, showNav] = useState(true);
	const [isNavBarSearch, setNavBarSearch] = useState({active:true});
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});
	const [isAlert, setAlert] = useState(false);
	const [playback, setPlayBack] = useState(null)
	const [message, setMsg] = useState('');
	const region = localStorage.getItem("region");

	const location = useLocation()
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const currentUser = localStorage.getItem("currentUser");
	const currentProfile = localStorage.getItem("selected_prof_id");
	const profileName = localStorage.getItem("profile_name");
	const localPlayBack = localStorage.getItem("playbackData")

	let content_id = location.state?.content_id
	const content_name = location.state?.content_name || 'No Title'
	const description  = location.state?.description || 'No description'
	const length = location.state?.length || 'No movie time length'
	const actors = location.state?.actors || []
	const header_image = location.state?.header_image || 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png'

	const {t} = useTranslation()

	useEffect(()=>{
		window.scroll(0,0);

		if($(window).width() <= 600){
			setNavBarSearch({...isNavBarSearch, active:false});
		}else{
			setNavBarSearch({...isNavBarSearch, active:true});
           
		}

		if(localPlayBack){
			const pbData = JSON.parse(localPlayBack)
			for (const content of pbData.contentList){
				if(content.contentId === content_id){
					console.log(content)
					setPlayBack(content)
				}
			}
		}
		
		//every 60 seconds check db for updated playback
		setInterval(async()=>{
			const data = await getPlayBackData()
			if(data.content.playBack.length > 0){
				for(const x of data.content.playBack){
					if(x.contentId === content_id){
						setPlayBack(x)
					}
				}
			}else{
				console.log('No PB in db')
			}
		}, 60000)
	},[])

	const getPlayBackData = async()=>{
		const res = await axios_.get(`/${currentUser}/content/${content_id}/stream`)
		return res.data
	}

	const watchingStyle ={
		text: playback !== null ? "Continue watching" : "Watch",
		buttonPaddingX: playback !== null ? "px-3" : "px-5",
		addToListbuttonPosition: playback !== null ? "add-to-list-btn-desc-pg_continue-watching" : "add-to-list-btn-desc-pg",
	}

	let showNavigation=()=>{
		showNav(!isNavNotShowing)
	}

	const convertLengthMilliToTime = (length)=>{
		const totalMinutes = Math.floor(length / 60000); // 1 minute = 60,000 milliseconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if(!isNaN(hours) && !isNaN(minutes)){
			return { hours, minutes };
		}

		return {hours:0, minutes:0}
	}

	const convertLengthSecondsToTime = (length)=>{
		const totalMinutes = Math.floor(length / 60); // 1 minute = 60seconds
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;
		if(!isNaN(hours) && !isNaN(minutes)){
			return { hours, minutes };
		}

		return {hours:0, minutes:0}
	}

	const addToList= async (e)=>{
		e.preventDefault()
		console.log(currentProfile)
		try{
			const res = await axios_.post(`/${currentUser}/user/${currentProfile}/my-list/${content_id}`)
			if(res.data.success){
				setMsg(res.data.message)
				setAlert(true)
			}else{
				console.log(res.data.message)
				setMsg(res.data.message)
				setAlert(true)
			}
		}catch(e){
			setAlert(false)
			console.log(e)
		}

	}

	useEffect(()=>{

		//Determine Mobile or not
		if($(window).width() <= 600){
			setNavBarSearch({...isNavBarSearch,active:false});
			setFooter({...isFooter, footerActive:false, bottomNavActive:true})
		}else{
			setNavBarSearch({...isNavBarSearch,active:true});
			setFooter({...isFooter, footerActive:true, bottomNavActive:false})
		}
	},[])

	return(
		<div className="bg-moviesPage">
			{isNavBarSearch.active ? <TopNavBar profile={profileName} region={region} navToggle={showNavigation} ></TopNavBar> : null }
			<span className="nav_drawer">{isNavNotShowing ? null : <NavDrawer closeDrawer={showNavigation}/>}</span>
            
			{isAlert ? <Toast message={message} paySuccess={isAlert}/> : null}

			<div className="">
				<img className="bg-image-desc" src={header_image} alt="video_header_image"/>
				<p className="movie-title">{content_name}</p>
				<p className="movie-time">{convertLengthSecondsToTime(length).hours > 0 || convertLengthSecondsToTime(length).minutes > 0 ? `${convertLengthSecondsToTime(length).hours}h ${convertLengthSecondsToTime(length).minutes}m` : `${Math.round(length)}s`}</p>
				<div className="movie_description ml-3 ml-md-5">
					{(() => {
						const splitDescription = description != undefined ? description.match(/.{1,50}/g) || [] : "" //maximum length of each line 50 chars each
						return splitDescription.length > 0 ? splitDescription.map((sentence, index) => (
							<p key={index}>{sentence}</p>
						)) : null
					})()}
				</div>
				<div>
					<Link to={`/watch/${content_id}/play`} state={{contentId: content_id, actors: actors}} className={`watch-btn-dsc-pg py-1 py-md-2 ${watchingStyle.buttonPaddingX}`}><img className="play-icon" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620554976/AfroWatch/Vector_cchtxo.svg"/>{watchingStyle.text}</Link>
					<Link to={'#'} className={`${watchingStyle.addToListbuttonPosition} py-1 px-5`} onClick={addToList}><img className="add-icon"src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620556117/AfroWatch/Vector_jz6b69.svg"/>{t("addToList")}</Link>
					{playback !== null ? <ProgressBar now={playback?.position} max={playback?.totalDuration} /> : null}
				</div>
			</div>
               
			<div className="container-fluid">
				<div className="row mt-5 mt-md-0">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">Cast</h1>
					</div>
				</div>

				{/* Cast */}
				<div className="row mt-5 mt-md-0 py-md-2">
					{actors.length > 0 ?actors.map((actor, pos)=>{
						return <div key={pos} className="col-4 col-md-2 text-center">
							{/* actor image */}
							<img className="movie-cast" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1693470115/test_avatar_agwzgp.jpg" alt=""/>
							<p className="movie-cast-name py-2">{actor}</p>
						</div>
					}): <div>No actors</div>} 
				</div>


				<div className="row py-md-4 similarMovies">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">Similar Anime</h1>
					</div>
				</div>

				
				<MovieSliderSimilarMovies actors={actors}/>
			

				<div className="row py-md-4 similarMovies">
					<div className="col-md-12">
						<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">Similar Actor Movies</h1>
					</div>
				</div>

				
				<MovieSliderSimilarMovies actors={actors}/>
				
			</div>

			{isFooter.footerActive ? <Footer/> : <BottomNav className="d-block d-md-none d-sm-block"/>}
		</div>
	)

}

export default MovieDescPage;