import React, {useState} from "react";
import { useNavigate } from "react-router";
import { axiosInstance } from "../../apiClient";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import Toast from "../Notification/Toast";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./Index.css";

const ForgotPassword = () => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [email, setEmail] = useState("");

	const navigate = useNavigate();
	const userId = localStorage.getItem('userId')

	const handleCheckEmail = async (e) => {
		e.preventDefault()

		if(email !== ""){
			try{
				const res = await axiosInstance.post(`/auth/v1.1/forgot-password/${email.toLowerCase()}`)
				console.log(res.data)

				if(res.data.success){
					setMessage("Please check your email !")
					setOpen(true);
					
					localStorage.setItem("email-reset", email.toLowerCase())
					navigate('/check-email')

					//Expire after 30 minutes
					setTimeout(()=> localStorage.removeItem('email-reset') , 1800000)
				}
			}catch(ex){
				console.log(ex)
			}
		}else{
			setMessage("No email input")
			setOpen(true);
		}
	}

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	return (
		<div style={{background: "#220B21"}}>
			<div
				style={{
					position: "relative",
					minHeight: "100vh",
				}}
				className="h-100 w-100"
			>
				<div className="custom-bg w-100 mx-auto">
					<div className="centered-container">
         
						<img
							src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706140890/AfroWatch/forgot-password/Afrowatch-Logo--Vertical-White-font_umkurt.svg"
							alt="logo"
							className="mb-4 d-block m-auto"
						/>
						<h5 className="text-white  mb-4 mt-4">Forgot Password</h5>

						<div>
							<form onSubmit={handleCheckEmail} method="post">
								<div className="mb-3">
									<label htmlFor="email" className="form-label">
										Email
									</label>
									<input
										type="email"
										className="form-control"
										id="email"
										placeholder="Email address"
										onChange={(e)=> setEmail(e.target.value)}
										value={email}
									/>
								</div>
								<span className="d-block mb-5 mt-3 fs-66">
									Enter the email associated with your account and we’ll send
									an email with instructions to reset the password.
								</span>

								<div className="d-grid gap-2">
									<button className="btn btn-primary btn-block py-2" type="submit">
										Send
									</button>
								</div>
							</form>

							<div className="text-center py-4"> 
								<Link style={
									{
										fontStyle: 'normal',
										fontWeight: 'bold', 
										fontSize: '16px',
										lineHeight: '27px', 
										color: '#06BA83', 
										textDecoration:'underline'
									}
								} to="/login">Login</Link>
							</div>
						</div>
            
					</div>
				</div>
			</div>

			<Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert
					onClose={handleClose}
					severity="success"
					variant="filled"
					sx={{ width: "100%" }}>
					{message}
				</Alert>
			</Snackbar>

			<Footer style={{ position: "absolute", bottom: 0, width: "100%" }} />
		</div>
	);
};

export default ForgotPassword;
