import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import "./MovieCard.css";
import PlayButton from "./PlayButton";

const MovieCard = (props) => {
	const [intitialState, setCardState] = useState({
		hover: false,
		played: false,
	});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	
	return (
		<Link
			key={props.content_id}
			to={`/watch/${props.content_id}`}
			state={{
				content_id: props.content_id,
				description: props.description,
				content_name: props.content_name,
				actors: props.actors,
				genre: props.genre,
				length: props.length,
				header_image: props.header_image
			}}
			onFocus={(e) => intitialState}
			onMouseEnter={(e) => setCardState({ hover: true })}
			onMouseLeave={(e) => setCardState({ hover: false })}
		>
			<div className="movie-card">
				<PlayButton/>
				<img src={props.image} alt="movie_img" className="content_image" />
			</div>
		</Link>
	);
};

export default MovieCard;
