import React, {useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom';
import NavBar from '../Nav/NavBar'
import NavBarSearch from '../Nav/NavBarSearch';
import Footer from '../Footer/Footer';
import axios from 'axios'
import StartYourPlan from '../Pricing/StartYourPlan';
import './PricingPlan.css'

const PricingPlan=()=>{
	const [plan, setPlan] = useState([])
	const [message, setMessage] = useState()

	const location = useLocation()
	console.log(location)

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const getPlans= async ()=>{
		const res = await axios_.get('/plans')
		if(res.status === 200){
			console.log(res.data)
			setPlan(res.data.plans)
		}
	}

	useEffect(()=>{
		getPlans()
		if(location.state?.message){
			setMessage(location.state?.message)
		}
		window.scrollTo(0,0)
	},[])

	const windowWidth = $(window).width();

	return plan.length ?(
		<div className="bg-pricingplan">
			{ windowWidth >= 600 ? <NavBarSearch/> :<NavBar/> }

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<h1 className="pricing-plan-text mt-md-5">Pricing Plan</h1>
						<div className="d-flex">
							<div className="light-pink-water mt-md-2"></div> <div className="light-pink-water-two ml-2 mt-md-2"></div>
						</div>
						<p className="pricing-p mt-5">Afrowatch gives room for various plans to meet your desired needs. The plan chosen determines, the number of screens at which you can access content at the same time as well as 
                        High or Ultra high definition quality.</p>

						<p className='pricing-p mt-1'>You also get a 30days free trial!</p>
						<div>{message ? message :null}</div>
					</div>
				</div>

				{windowWidth >= 600 ?
					<div className="row sec2-row justify-content-center">
						<div className="col-md-10">
							<table>
								<thead>
									<tr className="bg-first-row">
										<td></td>
										<td className="text-center px-md-5">
											<p className="plans">{plan[0].subscription_tier}</p>
											<p className="price">${plan[0].price} per month</p>
											<Link to='/pricing/start-your-plan/basic' state={{
												planName: plan[0].subscription_tier, 
												price:plan[0].price, 
												nollyWood:plan[0].region_allowed.includes(["W"]),
												gollyWood: plan[0].region_allowed.includes(["W"]),
												highDefinition: plan[0].high_definition,
												screens: plan[0].screens,
												devices: plan[0].screens,
												ultraHD: plan[0].ultraHighDefinition,
												priceId: plan[0].stripe_price_id,
												productId: plan[0].stripe_product_id
											}} className="btn btn-primary purchase-now-btn mx-1 mx-md-0">Purchase now</Link>
										</td>
                                        
										<td className="text-center px-md-5">
											<p className="plans">{plan[1].subscription_tier}</p>
											<p className="price">${plan[1].price} per month</p>
											<Link to='/pricing/start-your-plan/mid' state={{
												planName: plan[1].subscription_tier, 
												price:plan[1].price, 
												nollyWood:plan[1].region_allowed.includes(["W"]),
												gollyWood: plan[1].region_allowed.includes(["W"]),
												highDefinition: plan[1].high_definition,
												screens: plan[1].screens,
												devices: plan[1].screens,
												ultraHD: plan[1].ultraHighDefinition,
												priceId: plan[1].stripe_price_id,
												productId: plan[1].stripe_product_id
											}} className="btn btn-primary purchase-now-btn mx-1 mx-md-0">Purchase now</Link>
										</td>
                                        
										<td className="text-center px-md-5">
											<p className="plans">{plan[2].subscription_tier}</p>
											<p className="price">${plan[2].price} per month</p>
											<Link to='/pricing/start-your-plan/premium' state={{
												planName: plan[2].subscription_tier, 
												price:plan[2].price, 
												nollyWood:plan[2].region_allowed.includes(["W"]),
												gollyWood: plan[2].region_allowed.includes(["W"]),
												highDefinition: plan[2].high_definition,
												screens: plan[2].screens,
												devices: plan[2].screens,
												ultraHD: plan[2].ultraHighDefinition,
												priceId: plan[2].stripe_price_id,
												productId: plan[2].stripe_product_id
											}} className="btn btn-primary purchase-now-btn mx-1 mx-md-0">Purchase now</Link>
										</td> 
									</tr>
								</thead>

								<tbody>
									<tr className="light-purple">
										<td className="text-center px-md-4 py-md-3 py-2">Nigeria</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>

									<tr className="dark-purple-row">
										<td className="text-center px-md-4  px-3 py-md-3 py-2">Ghana</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>

									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-md-3">South Africa</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>

									<tr className="dark-purple-row">
										<td className="text-center px-md-4 px-3 py-3">Devices</td>
										<td className="text-center px-md-4 py-3">{plan[0].screens}</td>
										<td className="text-center px-md-4 py-md-3">{plan[1].screens}</td>
										<td className="text-center px-md-4 py-md-3">{plan[2].screens}</td>
									</tr>


									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-3">Screens</td>
										<td className="text-center px-md-4 py-3">{plan[0].screens}</td>
										<td className="text-center px-md-4 py-md-3">{plan[1].screens}</td>
										<td className="text-center px-md-4 py-md-3">{plan[2].screens}</td>
									</tr> 


									<tr className="dark-purple-row">
										<td className="text-center pl-md-4 py-md-3">Unlimited animation</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>


									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-md-3">Laptop, phone or tablet</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>


									<tr className="dark-purple-row"> 
										<td className="text-center px-md-4 px-3 py-md-3">HD available</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>


									<tr className="light-purple">
										<td className="text-center px-md-4 px-3 py-3">Ultra HD available</td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
										<td className="text-center px-md-4 py-md-3"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></td>
									</tr>

									<br/>

								</tbody>

							</table>
						</div>
					</div> 
					:
					<div className="row mt-5">
						{plan.map((p)=>{
							return <div className="col-12">
								<div className="card price-subscribe-card">
									<h1 className="start-plan-name text-center py-3">{p.subscription_tier}</h1>
									<p className="start-plan-price">${p.price} per month</p>
									<hr className="line-break"/>
									<div className="card-body">
										<div className="start-plan-list">
											<li className="py-2">NollyWood(old &amp; new) {!p.region_allowed.includes(["W"]) ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
											<li className="py-2">GollyWood {!p.region_allowed.includes(["W"]) ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
											<li className="py-2">Devices <span className="start-plan-tick">{p.screens} </span></li>
											<li className="py-2">Screens <span className="start-plan-tick">{p.screens} </span></li>
											<li className="py-2">High Definition {p.highDefinition ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
											<li className="py-2">Ultra HD{p.ultraHighDefinition ? <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/>:<img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620909774/AfroWatch/gug5ptexxawuux2nfvq6.svg" alt=""/>}</li>
											<li className="py-2">Watch on your Laptop, tablet or phone <img className="mt-1 start-plan-tick" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620906608/AfroWatch/dyjdfdnldcjsrhqnqqqq.svg" alt=""/></li>
										</div>  
                                        
										<hr className="line-break"/>
										<Link to={`/pricing/start-your-plan/${p.subscription_tier}`} state={{
											planName: p.subscription_tier, 
											price:p.price, 
											nollyWood:p.region_allowed.includes(["W"]),
											gollyWood: p.region_allowed.includes(["W"]),
											highDefinition: p.high_definition,
											screens: p.screens,
											devices: p.screens,
											ultraHD: p.ultraHighDefinition,
											priceId: p.stripe_price_id,
											productId: p.stripe_product_id
										}} className="btn btn-primary purchase-now-btn-mobile_pp mx-1 mx-md-0">Purchase now</Link>
									</div>
								</div>
							</div>
						})}
					</div>
				}
			</div>
			<Footer/>
		</div> 
	) : null
}

export default PricingPlan
