import React, { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios'
import './SplashScreen.css'

const SplashPage =()=>{
	const currentUser = localStorage.getItem('currentUser');
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const navigate = useNavigate()

	useEffect(()=>{
		const getUser = async()=>{
			const res = await axios_.get(`/user/${currentUser}`)
			const {data} =  res.data;
			if(res.data.success){
				return data
			}else{
				console.log(res.data.message)
			}
		}

		const user = getUser()
		// if(user.currentPlan != null){
		//     setTimeout(()=>{navigate('/whos-watching')}
		//     ,2000)
		// }else{
		//     navigate(`/pricing-plan`, {state: {message:'You need a plan to view content 😊'}});
		// }
        
		//only if you have a plan
		setTimeout(()=>{navigate('/whos-watching')}
			,2000)
	},[])

	return(
		<section className="bg-splash">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-12 text-center">
						<img className="afrowatch-logo-splash mt-5" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
					</div>
				</div>

				<div className="row justify-content-center">
					<div className="lds-ellipsis">
						<div className="1">
                            
						</div>

						<div className="2">

						</div>

						<div className="3"> 
                            
						</div>
                        
						<div className="4">

						</div>
					</div>
				</div>
			</div>
		</section>
	)
}


export default SplashPage;