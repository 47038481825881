import React, {useState, useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import NavBarSearch from '../Nav/TopNavBarV2';
import NavDrawer from '../Nav/NavDrawer';
import ModalCountrySelection from '../MoviesPage/ModalCountrySelection';
import MovieCard from '../MoviesPage/MovieCardComponent';
import $ from 'jquery';
import FilterMovieCard from './FilterMovieCard';
import SearchBar from '../Nav/SearchComponent'
import Footer from '../Footer/Footer';
import BottomNav from '../Nav/BottomNav';
import axios from 'axios'

const FilterPage=()=>{
	const [searchParams, setSearchParams] = useSearchParams();
	const [content_, setContent] = useState([])
	const [message, setMessage] = useState("")
	const [filter, setFilter] = useState()
	const [isNavNotShowing, showNav] = useState(true);
	const [isModal, setModalDisplay] = useState(false);
	const [isNavBarSearch, setNavBarSearch] = useState({active:true});
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});
	const [navValue , setValue]= useState(3);

	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const currentUser = localStorage.getItem("currentUser");
	const profileName = localStorage.getItem("profile_name");
	const region = localStorage.getItem("region");
	let genre =""

	useEffect(()=>{
		genre = searchParams.get('genre');
		console.log('genre', genre)

		getContentByFilter()
	},[searchParams.get('genre')])

	useEffect(()=>{
		if($(window).width() <= 600){
			setFooter({...isFooter, bottomNavActive:true, footerActive:false})
		}
	},[])

	const getContentByFilter= async ()=>{
		try{
			const res = await axios_.get('/content', { params: {genre: searchParams.get('genre'), userid:currentUser }})
			if(res.data.status == 200){
				console.log(res.data)
				setContent(res.data.content)
			}else{
				setContent([])
				setMessage(res.data.message)
			}
		}catch(e){
			console.log(e)
		}
	}

	const getContentBySearch=()=>{

	}

	const updateFilter = (sort) => {
		const obj ={}
		setSearchParams({ sort })
	}

	const showNavigation=()=>{
		showNav(!isNavNotShowing)
		setModalDisplay(false)
	}

	const toggleModal=()=>{
		isModal ? setModalDisplay(false):  setModalDisplay(true);
	}

	return(
		<div className="" style={{backgroundColor:'#241423', height:'100vh'}}>
			{!isFooter.bottomNavActive ? 
				<div>
					{isNavBarSearch.active ? <NavBarSearch region={region} navToggle={showNavigation} profile={profileName}></NavBarSearch> : null }
				</div> : <SearchBar value={''}/> 
			}
           
			<span className="nav_drawer">{isNavNotShowing ? null : <NavDrawer closeDrawer={showNavigation}/>}</span>
           
			{isModal ? <ModalCountrySelection closed={toggleModal} /> : null}

			<div className='row text-center mt-4'>
				<div className="col-md-12">
					<p className='pricing-plan-text mt-2'>{searchParams.get('genre')}</p>
				</div>
			</div>

			<div className="container">
				<div className='row mt-5'>
					{content_.length > 0 ? content_.map((content, pos)=>{
						return <div key={pos} className="col-md-3">
							<FilterMovieCard key={pos} image={content.video_image_url} content_id={content.content_id}
								actors={content.actors}
								content_name={content.content_name}
								date_uploaded={content.date_uploaded}
								genre={content.genre}
								video_path={content.video_path}
								length={content.length}
								description={content.content_description}/>
						</div>
					}) : <div className="col-sm-6 col-md-12"><div className='text-center'>{message || 'No content for this genre exists yet'}</div></div>}
				</div>
			</div>

			{/* {isFooter.bottomNavActive ? <BottomNav/> : <Footer/>} */}
		</div>
	)

}

export default FilterPage;