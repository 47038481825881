import React from 'react';
import ReactGA from 'react-ga4'
import { createRoot } from 'react-dom/client';
import App from './components/App';

import './utils/i18n'

ReactGA.initialize('G-J3QF1YYVX7');
ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname }); 

const container = document.getElementById('root');
const root = createRoot(container)
root.render(<App/>);
