import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {} from 'react-bootstrap';
import NavBar from '../Nav/NavBarNollyWoodPage'
import ModalCountrySelection from '../MoviesPage/ModalCountrySelection';
import '../MoviesPage/NollyWoodPage.css';

const AfrimeKidsSelect =()=>{
	const navigate = useNavigate()

	//explicitly set region as new component 
	localStorage.setItem('region',"Kids afrime");
	const userId = localStorage.getItem("currentUser");
	const region = localStorage.getItem("region");
	const [isModal, setModalDisplay] = useState(false);


	useEffect(()=>{
		if(!window.location.hash) {
			window.location = window.location + '#loaded';
			window.location.reload();
		}
	},[])

	const toggleModal=()=>{
		isModal ? setModalDisplay(false):  setModalDisplay(true);
	}


	return(
		<div className="bg-sec-nollywood-page">
			<NavBar region={region}/>

			{isModal ? <ModalCountrySelection closed={toggleModal} /> : null}
			<div className="container">
				<div className="row">
                   
				</div>

				<div className="row justify-content-center mt-md-5">
					<div className="col-md-6">
						<Link className="" onClick={(e)=>{}} style={{textDecoration: 'none', position:'relative', top:'156px'}} to='/'>
							<div className="card country-card" style={{background: '#C92941', backgroundSize:'contain'}}>
								<div className="card-body">
									<p className="text-center modal-text py-md-4">Entertainment</p>
								</div>  
							</div>
						</Link>
					</div>

					<div className="col-md-6">
						<Link onClick={(e)=>{}} style={{textDecoration: 'none', position:'relative', top:'156px'}} to={{ pathname: `/profile/${userId}/afrime/education` }} target="_blank">
							<div className="card country-card" style={{background: '#C92941', backgroundSize:'contain'}}>
								<div className="card-body">
									<p className="text-center modal-text py-md-4">Education</p>
								</div>  
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	)

}

export default AfrimeKidsSelect;