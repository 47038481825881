import React, {createContext} from 'react';

export const ThemeContext = createContext();

class ThemeContextProvider extends React.Component{

	state={
		isAfrimeLightTheme: true,
		afrimeDarkTheme:{text:'white', background:'url(https://images.unsplash.com/photo-1608874973445-de098faf870f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1650&q=80)'},
		afrimeLightTheme:{
			text:'black',
			background:'url(https://images.unsplash.com/photo-1581833971358-2c8b550f87b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1651&q=80)'
		},
		isGlobalLightTheme:true,
		lightTheme:{text:'#C92941', background:'rgb(50, 29, 49)'},
		darkTheme:{text:'white', background:'#2D132C'}
	}

	changeTheme=()=>{
		this.setState({isAfrimeLightTheme: !this.state.isAfrimeLightTheme})
	}

	changeGlobalTheme=()=>{
		this.setState({isGlobalLightTheme: !this.state.isGlobalLightTheme})
	}
    

	render(){
		return(
		// Spread operator takes all different properties inside the state including its keys and values
			<ThemeContext.Provider value={{...this.state, changeTheme: this.changeTheme, changeGlobalTheme: this.changeGlobalTheme}}>
				{this.props.children}
			</ThemeContext.Provider>
		);
	}

}

export default ThemeContextProvider;