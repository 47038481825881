function calculatePointInTimelinePercent(progressContainer, currentXPosition) {
	const rect = progressContainer.getBoundingClientRect()
	return Math.min(Math.max(0, currentXPosition - rect.x), rect.width) / rect.width
}

export function applyTimelineListenersToPlayer(player, videoThumbnailImages) {
	const videoPlayerElement = player.el()
	const timelineContainer = videoPlayerElement.querySelector(".vjs-progress-control")
	const slider = timelineContainer.querySelector(".vjs-progress-control .vjs-slider")

	// add preview img
	const previewImgViewer = document.createElement("img")
	previewImgViewer.classList.add("preview-thumbnail")

	slider.appendChild(previewImgViewer)
	// const previewImg = videoContainerElement.querySelector(".preview-img")
	// const thumbnailImg = videoContainerElement.querySelector(".thumbnail-img")

	// let isScrubbing = false
	// let wasPaused

	function handleTimelineUpdate(event) {
		const percent = calculatePointInTimelinePercent(slider, event.x)
		let thumbNailId = Math.min(Math.floor(videoThumbnailImages.length * percent), (videoThumbnailImages.length - 1))
		previewImgViewer.src = `${process.env.REACT_APP_BASE_STREAM_URL}/${videoThumbnailImages[thumbNailId]}`
		timelineContainer.style.setProperty("--preview-position", percent)

		// if (isScrubbing) {
		//     event.preventDefault()
		//     thumbnailImg.src = previewThumbnail
		//     timelineContainer.style.setProperty("--progress-position", percent)
		// }
	}

	// function toggleScrubbing(event) {
	//     const percent = calculatePointInTimelinePercent(timelineContainer, event.x)
	//     isScrubbing = (event.buttons & 1) === 1
	//     videoContainerElement.classList.toggle("scrubbing", isScrubbing)
	//     if (isScrubbing) {
	//         wasPaused = videoElement.paused
	//         videoElement.pause()
	//     } else {
	//         videoElement.currentTime = percent * videoElement.duration
	//         if (!wasPaused) videoElement.play()
	//     }
	//     handleTimelineUpdate(event)
	// }

	function moveVideoToLocation(){

	}


	// document.addEventListener("mouseup", e => {
	//     if (isScrubbing) toggleScrubbing(e)
	// })
	// document.addEventListener("mousemove", e => {
	//     if (isScrubbing) handleTimelineUpdate(e)
	// })

	timelineContainer.addEventListener("mousemove", handleTimelineUpdate)
	// timelineContainer.addEventListener("mousedown", toggleScrubbing)
	// timelineContainer.addEventListener("click", moveVideoToLocation)
	// return {}
}

