import React, {useEffect, useContext, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import SearchBar from '../Nav/SearchComponent';
import ModalCountrySelection from '../MoviesPage/ModalCountrySelection';
import './NavBarSearch.css';
import {AuthContext }from '../../context/AuthContext';

const NavBarSearch=(props)=>{
	const {user, setUser} = useContext(AuthContext);
	const [isModal, setModalDisplay] = useState(false);
	const [showLogOut, setLogOut] = useState(false);
	const currentUser  = localStorage.getItem('currentUser');
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const navigate = useNavigate()

	useEffect(()=>{
		//console.log(props)
	},[])

	const toggleModal=()=>{
		isModal ? setModalDisplay(false):setModalDisplay(true);
	}

	const showNav=()=>{
		props.navToggle()
		setModalDisplay(false)
	}

	const logOut= async()=>{
		const resp = await axios_.get('/auth/logout')
		console.log(resp.message);
        
		if(resp.data.success){
			setUser({});
			localStorage.clear();
			navigate('/login')
		}
	}

	const toggleLogOut=()=>{
		if(showLogOut){
			setLogOut(false)
		}else{
			setLogOut(true)
		}

		setTimeout(()=>{
			setLogOut(false)
		},5000)
	}

	let textSize
	if(props.region){
		textSize = props.region.length>6 ? '13px' : '16px'
	}

	return(
		<nav className="navbar navbar-expand-lg navbar-light bg-dark">
			<Link onClick={showNav} to={'#'} ><img className="navigation" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866913/AfroWatch/Group_3_rnlqdu.svg" alt=""/></Link>
			<a className="navbar-brand" href="/"><img className="navbarbrand-img" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744963/AfroWatch/Afrowatch_Horizonal_Logo_-_White_pq1iyh.png" alt=""/></a>
            
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>

			<div>
				{props.region ? <span className='ml-5'><Link style={{color:'white', fontSize:textSize}} to='#' onClick={toggleModal}>Region: {props.region}</Link></span> : <span>No region selected yet</span>}
			</div>

			<div className='ml-5' style={{fontSize:'14px'}}>Profile: {props.profile ? props.profile : null}</div>

			{isModal ? <ModalCountrySelection closed={toggleModal} /> : null}

			<div className="collapse navbar-collapse" id="navbarSupportedContent">
				<ul className="navbar-nav ml-auto">
					<SearchBar/>
					<button className="notification-btn border-0 px-md-5"><img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866913/AfroWatch/Group_4_rc9j1n.svg" alt=""/></button>
					{currentUser ? <Link onMouseOver={toggleLogOut} className="pr-md-3" to={`/profile/${currentUser}`}><img className="profile-icon" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866914/AfroWatch/Group_5_pifpe3.svg" alt=""/></Link> : null}
				</ul>
			</div>

			{showLogOut ?
				<div className='logout'>
					<span style={{color:'#D02A3A',position:'absolute',right: '53px',top: '65px'}}>&#x25B2;</span>
					<button type="button" style={{position:'absolute', top:'80px', right:'17px', width:'90px'}} className="btn btn-danger" onClick={logOut}>Log out</button>
				</div>
				: null} 
		</nav>
	)
}

export default NavBarSearch;