import React,{useEffect,useState} from 'react';
import axios from 'axios'

const Account=(props)=>{
	const currentUser = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");
	const profileIcon = props.profileIcon ? props.profileIcon : localStorage.getItem("profile_icon")

	const [profileState, setProfile] = useState({firstName:'', lastName:'', email:''});
	const [user_, setUser] = useState()
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`)
		const {data} =  resp.data;
		if(resp.data.success){
			setUser(data)
		}else{
			console.log(resp.data.message)
		}
	}

	const updateUser= async (e)=>{
		e.preventDefault()
		let fullName = profileState.firstName+" "+profileState.lastName;

		const body = {
			fullName: fullName.trim(),
			email:profileState.email.trim()
		}

		const resp = await axios_.put(`${currentUser}/user`, body)
		console.log(resp.data);

		if(resp.data.success){
			const {data} = resp.data
			console.log(data.fullName);

			let name = data.fullName.split(" ");   
			let firstName = name[0] ;
			let lastName = name[1];
			setProfile({...profileState, firstName:firstName, lastName:lastName, email:data.email})
			//show alert
		}else{
			console.log(resp.data.message)
		}
	}


	useEffect(()=>{
		getUser()
	},[])


	return(
		<div className="container">
			<div className="row mt-5">
				<div className="col-md-12"> 

					<form id="uploadImage" action="" method="post">
						<label htmlFor="file-input">
							{/* Image string from back-end */}
							<img className="profile-picture mr-md-3" src={profileIcon} alt=""/>
							<img className="edit-pencil" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621110573/AfroWatch/Group_fibd57.svg" alt=""/>
						</label>
						<input id="file-input" type="file"/>
					</form>

					<form onSubmit={updateUser} action="" method="post">
						<div className="row d-flex">
							<div className="col-md-6">
								{
									user_ ?
										(user_.profiles[0].profile_name === currentProfile ? 
											<input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} className="form-control my-3" type="text" name="firstName" defaultValue={user_ ? user_.fullName.split(" ")[0] : ""}  placeholder="First name" required/>
											:<input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} style={{backgroundColor:'inherit'}} className="form-control my-md-3" type="text" name="firstName" defaultValue={user_ ? user_.fullName.split(" ")[0] : ""}  placeholder="First name" required disabled/>
										)
										: null
								}
							</div>

							<div className="col-md-6">
								{
									user_ ?
										(user_.profiles[0].profile_name === currentProfile ? 
											<input onChange={(e)=>setProfile({...profileState, lastName:e.target.value})} className="form-control my-3" name="lastName" type="text" defaultValue={user_ ? user_.fullName.split(" ")[1]: ""}  placeholder="Last name" required/>
											:<input onChange={(e)=>setProfile({...profileState, lastName:e.target.value})} style={{backgroundColor:'inherit'}} className="form-control my-md-3" name="lastName" type="text" defaultValue={user_ ? user_.fullName.split(" ")[1]: ""}  placeholder="Last name" required disabled/>
										)
										: null
								}
							</div>
						</div>

						<div className="row">
							<div className="col-md-6">
								{
									user_ ?
										(user_.profiles[0].profile_name === currentProfile ? 
											<input onChange={(e)=>setProfile({...profileState, email:e.target.value})} className="form-control mt-3" name="email" type="email" defaultValue={user_ ? user_.email: ""} placeholder="email" required/>
											:<input onChange={(e)=>setProfile({...profileState, email:e.target.value})} style={{backgroundColor:'inherit'}} className="form-control mt-md-3" name="email" type="email" defaultValue={user_ ? user_.email: ""} placeholder="email" required disabled/>
										)
										: null
								}
							</div>
						</div>

						{user_ ?
							(user_.profiles[0].profile_name === currentProfile ?
								<div className="row">
									<div className="col-md-12">
										<button className="edit-prof-btn py-1 px-5 update-prof-button" type="submit">Update</button>
									</div>
								</div> : null
							) 
							: null
						}
					</form>
				</div>
			</div>
		</div>
	)

}

export default Account;