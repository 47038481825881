import React, {useEffect, useState} from 'react'
import { Snackbar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


const Toast =({message, paySuccess})=>{
	const [snackOpen, setSnackOpen] = useState({alert: false})

	useEffect(()=>{
		setSnackOpen({...snackOpen, alert:paySuccess})
	},[paySuccess])

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen({...snackOpen, alert:false});
	};

	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);


	return(
		<div>
			<Snackbar open={snackOpen.alert} autoHideDuration={4000} onClose={handleSnackBarClose} message={message} action={action} />
		</div>
	)
}

export default Toast;