import React,{useEffect,useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toast from '../Notification/Toast';
import axios from 'axios'

const ProfileIcon=(props)=>{
	const currentUser = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");
	const currentProfileId = localStorage.getItem("profile_id");

	const [profileIcon, setProfileIcon] = useState({icon:'', selected:false});
	const [profileIcons, setProfileIcons] = useState({icons:[]});
	const [alert, setAlert] = useState(false);
	const [message, setMessage] = useState('')
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const getProfileIcons=async ()=>{
		const res = await axios_.get('/profile-icons')
		const {data} = res.data
		try{
			if(res.data.success){
				setProfileIcons(data)
			}else{
				setProfileIcons([])
			}
		}catch(e){
			console.log(e)
		}
	}

	const updateProfileIcon= async (e)=>{
		e.preventDefault()
		try{
			const resp = await axios_.put(`${currentUser}/user/${currentProfileId}/icon`, {icon:profileIcon.icon})
			console.log(resp.data);
			if(resp.data.success){
				console.log('updated successfully')
				setMessage('updated successfully')
				setAlert(true)
				//set back to original selected profile 
				localStorage.setItem('profile_id', localStorage.getItem('selected_prof_id'))
			}else{
				setMessage('error')
				setAlert(true)
			}

			//refresh for now, to see changes
			setTimeout(window.location.reload(), 700)
		}catch(e){
			console.log(e)
		}
	}
    
	const closeCard=()=>{
		setProfileIcons({...profileIcons, icons:[]})
	}

	const selectCard=()=>{
		localStorage.setItem('profile_id', props.profile_id)
		const cards = document.getElementsByClassName('profile_icon_unselected')
		let previousCard = null;

		for(let card of cards){
			card.addEventListener('click', (e)=>{
				//previous card exists
				if (previousCard) {
					previousCard.style.transform = 'scale(1.0)';
				}
				card.style.transform = 'scale(1.2)';
				previousCard = card;
			})
		}
	}


	useEffect(()=>{
	},[])

	const theme = createTheme({
		palette: {
			secondary: {
				main: '#C92941',
			},
		},
	});


	return(
		<div className="container">
			<div className="row">
				<div className="col-md-12"> 
					<img key={props.profile_icon} onClick={getProfileIcons} className='ml-3 mt-md-2' style={{width:'50px', height:'50px', borderRadius:'10px'}} src={props.profile_icon} alt="profile_icon"/>
					<img className="edit-pencil_profile_icon" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621110573/AfroWatch/Group_fibd57.svg" alt=""/>
                        
					{ alert ? <Toast message={message} paySuccess={alert}/> : null}

					{
						profileIcons ? 
							(
								profileIcons.length > 0 ?
									<Card style={{overflowY:'scroll', height:'250px'}}>
										<ThemeProvider theme={theme}>
											<span style={{position:'absolute',right:'16px'}} onClick={closeCard}>
												<CancelIcon color="secondary"/>
											</span>
										</ThemeProvider>
										<CardContent>
											<span style={{color:'black', fontSize:'12px'}}>scroll...</span>
											<div className="row">
												{profileIcons.map((icon_, pos)=>{
													return <div key={pos} className="col-md-3">
														<img onClick={()=>{setProfileIcon({...profileIcon, icon:icon_.profile_icon}); selectCard()}} src={icon_.profile_icon} 
															className={profileIcon.selected ? 'profile_icon_selected mx-1 my-1' : 'profile_icon_unselected mx-1 my-1'}
															alt="icon" />
													</div>
                                               
												})}
											</div>
										</CardContent>
										<CardActions>
											<ThemeProvider theme={theme}>
												<Button color='secondary' onClick={updateProfileIcon} size="small">update</Button>
											</ThemeProvider>
										</CardActions>
									</Card>
									:null
							) 
							: null
					}
				</div>
			</div>
		</div>
	)

}

export default ProfileIcon;