import { axiosInstance } from "../../../apiClient";
import existingStreamPlaybackDataApiResponse from "../../../examples/existingStreamPlaybackDataResponse.json";

export const getContentPlayBackDataFromLocalStorage = (profileId, contentId) => {
	if(contentId){
		let playbackData = localStorage.getItem("playbackData")

		if (playbackData) {
			const playBack = JSON.parse(playbackData)
			console.log(playBack)
			return playBack.contentList.find((playback) =>  playback.profileId === profileId  && playback.contentId === contentId)
		}
		return null
	}
}

/**
 *  Make request to DB to see if playback data may exist in the DB, this can only occur in 2 situations
 *  1. User watched video on a different device, and wants to continue it on another device
 *  2. User cleared cache on browser.
 * 
	playback data to local storage in browser occurs every 15 SECONDS.
	playback data to afrowatch database occurs every minute (60 SECONDS).
 */

export const getContentPlayBackDataFromDB = async (userId, profileId, contentId) => {
	console.log(userId, profileId, contentId)

	try{
		const resp = await axiosInstance.get(`/${userId}/${profileId}/playback/track/${contentId}`)
		console.log(resp.data)

		if (resp.data.status === 200 && resp.data.contentPlayBack.length > 1) { 
			return resp.data
		}else{
			console.log('Could not get playback data from db either or playback data empty in db.')
			//if its still successfully got a response (with no playback data) use that rather than the example
			return resp.data.status === 400 ? resp.data : null
		}
	} catch(ex) {
		console.info(`Could not get content playback data from DB: ${ex}`)
		return null
	}
}

export const getContentStreamPlayBackData = async (contentId) => {
	console.log('ContentId=', contentId)

	const userId = localStorage.getItem('currentUser')
	const profileId = localStorage.getItem('selected_prof_id')

	if (contentId !== undefined) {
		let playbackData = getContentPlayBackDataFromLocalStorage(profileId, contentId)
		if (playbackData) {
			return {
				origin: "local-storage",
				playback: getContentPlayBackDataFromLocalStorage(profileId ,contentId)
			}
		} else {
			console.info("No playback data in local storage, searching afrowatch db")
			try {
				const res = await getContentPlayBackDataFromDB(userId, profileId, contentId)
				if (res.status === 200 && res.data.contentPlayBack) return {
					origin: "db",
					data: res.data.contentPlayBack
				}

			} catch (err) {
				console.log(err)
			}
		}
	}
	return null
}

export const setProfileStreamPlayBackData = (profileId, contentId, currentTime = 0, totalTime = 0) => {
	const playbackDataToStore = {profileId: profileId, contentId: contentId, position: currentTime, totalDuration:totalTime, timeUpdated: Date.now()}

	const localPlaybackDataString = localStorage.getItem("playbackData")

	if (localPlaybackDataString === null) {
		localStorage.setItem("playbackData", JSON.stringify({ contentList: [playbackDataToStore] }))
	} else {
		let localPlaybackData = JSON.parse(localPlaybackDataString)
		localPlaybackData.contentList = localPlaybackData
			.contentList
			.map((data) => {
				return ( data.profileId === profileId && data.contentId === contentId) ? playbackDataToStore : data
			})
		localStorage.setItem("playbackData", JSON.stringify(localPlaybackData))
	}
	return playbackDataToStore
}

export async function uploadUserPlayBackData(userId, profileId, contentId) {
	const contentPlayBackData = getContentPlayBackDataFromLocalStorage(profileId, contentId)
	console.log("fromDbAdd:" + contentPlayBackData)

	if (contentPlayBackData === null) {
		console.error("No playback data was found in the localStorage for profile")
	}

	// upload profile playback data to db
	try {
		const resp = await axiosInstance.post(`/${userId}/${profileId}/playback/track/${contentId}`, contentPlayBackData)
		if (resp.data.success) {return true}
	} catch (e) {
		console.error(`Could not upload playback: ${e}`)
		return false
	}
	return false

}
