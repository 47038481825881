import {useState} from 'react'
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga'
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Toast from '../Notification/Toast';

const Pay = () => {
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate()

	const [paySuccess, setSuccess] = useState(false)
	const [loading, setLoading] = useState(false)
	const [alertMessage, setMessage] = useState("")

	const pay = async (event) => {
		event.preventDefault();
		//complete the payment using details from the Payment Element and activate the subscription
		setLoading(true)
		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			return;
		}

		const result = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url: "https://beta.afrowatch.co/whos-watching", //http://localhost:3000/
			},
		});

		if (result.error) {
			// Alert error to your customer (for example, payment details incomplete)
			console.log(result.error.message);
			setSuccess(false)
			setMessage(result.error.message)
		} else {
			setLoading(false)
			//Alert successful
			setSuccess(true)
			setMessage('Succesfully paid for subscription')
			//navigate 
			setTimeout(()=>{navigate('/whos-watching')},2000)
            
			//Send email with sendgrid

		}

		ReactGA.event({
			category: 'Plans & Subscription',
			action: `Payment button clicked in stripe U.I`
		})
	};

	return (
		<form id='payment-form' onSubmit={pay}>
			<PaymentElement />
			<button className='subscribe-btn mt-2'>Submit</button>
			{loading ? 
				<div> 
					<CircularProgress
						size={15}
						sx={{
							color: green[500],
							zIndex: 1,
						}}
					/> 
				</div>: null}

			{paySuccess ? <Toast message={alertMessage} paySuccess={paySuccess}/> : <Toast message={alertMessage} paySuccess={paySuccess}/>}
		</form>
	)
};

export default Pay