import {useState, useEffect} from 'react';
import axios from 'axios';
import Video from "./Video";
import "./index.css";
import VideoSidebar from "./VideoSidebar";


function ShortIndex() {
	const axios_ = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
	const [shortVidoes, setShortVidoes] = useState([]);
	const [shortVidoeContent, setShortVidoeContent] = useState([]);
	const [shortVidoeStream, setShortVidoeStream] = useState([]);
	const userId = localStorage.getItem("currentUser");

    
	useEffect(()=>{
		getShortvideos();
		//Determine Mobile or not
	},[])

	
	const getShortvideos = async ()=>{
		const res = await axios_.get(`/${userId}/afclips/stream`)
		const { data } = res.data
		if(res.status === 200){
			setShortVidoes(data);
			setShortVidoeContent(data.content);
			setShortVidoeStream(data.stream);
		}else{
			console.log(res.data.message)
		}
	}

	// Function to merge arrays by ID
	function mergeArraysBySameId(arr1, arr2) {
		const merged = [];
  
		// Merge items with the same ID
		arr1.forEach(obj1 => {
			const obj2 = arr2.find(item => item.content_id === obj1.content_id);
			if (obj2) {
				merged.push({ ...obj1, ...obj2 });
			} else {
				merged.push(obj1);
			}
		});
  
		// Add remaining items from arr2
		arr2.forEach(obj2 => {
			const obj1 = arr1.find(item => item.id === obj2.id);
			if (!obj1) {
				merged.push(obj2);
			}
		});
  
		return merged;
	}
  
	const mergedArray = mergeArraysBySameId(shortVidoeContent, shortVidoeStream);

	// const videos = [
	// 	{
	// 		id: 1,
	// 		url: "https://firebasestorage.googleapis.com/v0/b/afclip-8d2cb.appspot.com/o/2024_02_28_09_30_52_002_001.mp4?alt=media&token=41dbe961-4571-478b-aefb-4ae6f4984b26", // 30 seconds video
	// 		channel: "Channel 1",
	// 		song: "Dummy Song 1",
	// 		likes: 100,
	// 		messages: 20,
	// 		description: "Description of Video 1",
	// 		shares: 10,
	// 	},
	// 	{
	// 		id: 2,
	// 		url: "https://firebasestorage.googleapis.com/v0/b/afclip-8d2cb.appspot.com/o/CalledOut%20Music%20-%20I%20AM%20FREE%20(Official%20Lyric%20Video).mp4?alt=media&token=d6ba3c66-00c3-4e70-908b-6624d66cca50", // 45 seconds video
	// 		channel: "Channel 2",
	// 		song: "Dummy Song 2",
	// 		likes: 200,
	// 		messages: 30,
	// 		description: "Description of Video 2",
	// 		shares: 15,
	// 	},
	// 	{
	// 		id: 3,
	// 		url: "https://firebasestorage.googleapis.com/v0/b/afclip-8d2cb.appspot.com/o/Muad%20X%20Zayaan%20-%20Angels%20At%20The%20Gates%20(Vocals%20Only).mp4?alt=media&token=979de601-7e1c-4400-8302-261f43428671", // 50 seconds video
	// 		channel: "Channel 3",
	// 		song: "Dummy Song 3",
	// 		likes: 150,
	// 		messages: 25,
	// 		description: "Description of Video 3",
	// 		shares: 12,
	// 	},
	// ];

	const newvideos = mergedArray && mergedArray?.map((item) => {
		return {
			id: item.content_id,
			url: item.hls_stream_path,
			channel: item.animators && item.animators.map((anime) => anime).join(", "),
			song: item.audio_path, 
			description: item.content_description,
			likes: 30, 
			messages: 50,
			shares: 130,
		}
	})
	console.log({ newvideos });
	return (
		<div className="app">
			<div className="app__videos">
				{newvideos && newvideos.map(
					({
						id,
						url,
						channel,
						description,
						song,
						likes,
						messages,
						shares,
					}) => (
						<Video
							key={id}
							url={url}
							channel={channel}
							song={song}
							likes={likes}
							messages={messages}
							description={description}
							shares={shares}
						/>
					)
				)}
			</div>
		</div>
	);
}

export default ShortIndex;
