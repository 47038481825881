import React, { useRef, useState, useEffect } from "react";
import videojs from "video.js";
import Hls from "hls.js";
import VideoFooter from './VideoFooter';
import VideoSidebar from "./VideoSidebar";
import "./Video.css";

function Video({ url, channel, description, song, likes, messages, shares }) {
	const [playing, setPlaying] = useState(false);
	const [player, setPlayer] = useState(null);
	const videoRef = useRef(null);
	const playerRef = useRef(null);
	let hls;

	useEffect(() => {
		const video = videoRef.current;
		if (Hls.isSupported()) {
			hls = new Hls();
			hls.loadSource( `${process.env.REACT_APP_BASE_STREAM_URL}/${url}`);
			hls.attachMedia(video);
		} else if (video.canPlayType("application/x-mpegURL")) {
			video.src = url;
		}
    
		const handleMouseEnter = () => {
			video.controls = true;
		};
    
		const handleMouseLeave = () => {
			video.controls = false;
		};

	
		video.addEventListener("click", onVideoPress);
    
		video.addEventListener("mouseenter", handleMouseEnter);
		video.addEventListener("mouseleave", handleMouseLeave);
    
		return () => {
			if (hls) {
				hls.destroy();
			}
			video.removeEventListener("click", onVideoPress);
			video.removeEventListener("mouseenter", handleMouseEnter);
			video.removeEventListener("mouseleave", handleMouseLeave);
		};
	}, [url]);

	const onVideoPress = () => {
		if (playing && videoRef.current) {
			videoRef.current.pause();
			//player.pause();
			setPlaying(false);
		} else {
			videoRef.current.play();
			//player.play();
			setPlaying(true);
		}
	};

	// const onReady = (player) => {
	// 	playerRef.current = player;
	// 	// You can handle player events here, for example:
	// 	player.on('waiting', () => {
	// 		videojs.log('player is waiting');
	// 	});
	
	// 	player.on('dispose', () => {
	// 		videojs.log('player will dispose');
	// 	});
	// };

    
	// const handleKeyDown = (event) => {
	// 	if (event.keyCode === 32) {
	// 		if(player.paused()){
	// 			player.play()
	// 		}else{
	// 			player.pause();
	// 		}
	// 	}
	
	// 	if (event.keyCode === 70) {
	// 		player.toggleFullscreen();
	// 	}
	// };
	
	// const options = {
	// 	autoplay: false,
	// 	controls: false,
	// 	responsive: true,
	// 	fluid: true,
	// 	preload: "auto",
	// 	enableSmoothSeeking: true,
	// 	aspectRatio: '16:9',
	// 	width: 5000,
	// 	fullscreen: {
	// 		options: {navigationUI: 'auto'}
            
	// 	}
	// };

    
	// useEffect(() => {
	// 	try {
	// 		let player_;
	// 		if (!playerRef.current) {
	// 			// The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
	// 			const videoElement = document.createElement("video-js");
	// 			videoElement.classList.add("vjs-big-play-centered");
	// 			videoRef.current.appendChild(videoElement);
	// 			const player = playerRef.current = videojs(videoElement, options, () => {
	// 				videojs.log('player is ready');
	// 				onReady && onReady(player);
	// 			});

	// 			// Handle Keyboard events
	// 			document.addEventListener("keydown", handleKeyDown);
	// 			//videoRef.current.addEventListener("click", onVideoPress);
	// 			// Handle Keyboard events
	// 			player_ = player;
	// 			setPlayer(player_);
	// 			//videoRef.current.style.transform = "scale(1.5)";

	// 			// player.autoplay();
	// 			// player.src(options.sources);
	// 			try {
	// 				player_.src({ 
	// 					src:  `${process.env.REACT_APP_BASE_STREAM_URL}${url}`,
	// 					type: "application/x-mpegURL", //video/mp4 application/x-mpegURL,
	// 				});
	// 			} catch (e) {
	// 				console.log(e);
	// 				// when its the default home page and there is no content_id passed in URL yet. basically for the display video (trending)
	// 			}

	// 		} else {
	// 			const player = playerRef.current;

	// 			player.autoplay(options.autoplay);
	// 			player.src(options.sources);
	// 			try {
	// 				player_.src({ 
	// 					src:  `${process.env.REACT_APP_BASE_STREAM_URL}/${url}`,
	// 					type: "application/x-mpegURL", //video/mp4 application/x-mpegURL,
	// 				});
	// 			} catch (e) {
	// 				console.log(e);
	// 				// when its the default home page and there is no content_id passed in URL yet. basically for the display video (trending)
	// 				if (!params.contentid) {
	// 					console.log("no content id");
	// 					//make request to get content. filter content where isDisplay == true and get the content_id.
	// 				}
	// 			}
	// 		}

			
	// 	} catch (e) {
	// 		console.log(e);
	// 	}
	// }, [videoRef]);

	// Dispose the player when the component will unmount
	// useEffect(() => {
	// 	const player = playerRef.current;

	// 	return () => {
	// 		if (player && !player.isDisposed()) {
	// 			player.dispose();
	// 			playerRef.current = null;
	// 			document.removeEventListener("keydown", handleKeyDown);
	// 		}
	// 	};
	// }, [playerRef]);

	return (
		<>
			<div className="video">
				<video className="video__player" onClick={onVideoPress} ref={videoRef} controls autoPlay loop/>;
				{/* <video
					className="video__player"
					ref={videoRef}
					onClick={onVideoPress}

					autoPlay
					loop
				>
					<source src={url} type="video/mp4"/>
				</video> */}
				{/* <div data-vjs-player style={{ width: "100%", height: "100%" }}> 
					<div ref={videoRef}  className="video__player video-js" onClick={onVideoPress}   />
				</div> */}
					
				{/* {
					!playing  && (
						<div style={{position: 'absolute', top: '20rem', right: '40%', bottom: '0', display: 'flex', justifyContent: 'center'}}>
							<IconButton aria-label="play" onClick={onVideoPress} style={{width: '5rem', height: '5rem'}}>
								<PlayArrowRoundedIcon style={{color: 'white', fontSize: '5rem'}}/>
							</IconButton>
						</div>
					)
				} */}
				<VideoFooter channel={channel} description={description} song={song} />
			
				{/* <VideoSidebar likes={likes} messages={messages} shares={shares} url={url} /> */}
			</div>

		</>
	);
}

export default Video;
