import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom'
import {Card, CardContent, CardActions, Typography} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'

const ProfileCard =(props)=>{
	const[profileState, setProfile] = useState({name: '', language:''})
	const [snackOpen, setSnackOpen] = useState({alert:false, message:''})

	const updateUserAccount= async(e)=>{
		e.preventDefault();
		const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

		const user = {
			name: profileState.name.trim()
		}

		const userId = localStorage.getItem('currentUser')

		const requestConfig = {
			method:'PUT',
			headers: { 'Content-Type': 'application/json' ,'Accept': 'application/json'},
			params: {userid: userId},
		}

		const resp = await axios_.put(`/${userId}/user/profile`, user, requestConfig)
		if(resp.data.success){
			console.log(resp.data)
			setProfile({...profileState, name:""})
			setSnackOpen({...snackOpen, alert:true, message:resp.data.message})
			setTimeout(()=>{
				window.location.reload()
			},500)
		}else{
			setProfile({...profileState, name:""})
			setSnackOpen({...snackOpen, alert:true, message:resp.data.message})
		}
	}

	const handleSnackBarClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setSnackOpen({...snackOpen, alert:false});
	};


	const action = (
		<React.Fragment>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackBarClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</React.Fragment>
	);

	return (
		<div className='mobile-profile-form'>
			{snackOpen.alert ? <Snackbar open={snackOpen.alert} autoHideDuration={5000} onClose={handleSnackBarClose} message={snackOpen.message} action={action} /> : null}
                 
			<Card sx={{ minWidth:350, color:'black' }} >
				<CardContent>
					<div className="text-left" style={{color:'black'}}>Add profile:</div>
					<input className="form-control my-3" onChange={(e)=>setProfile({name:e.target.value})} type="text" value={profileState.name} placeholder="Full Name"/>

					<div className="d-flex">
						<button onClick={updateUserAccount} type="submit" className="btn btn-success mr-4">Save changes</button>
						<button onClick={props.closeCard} className="btn btn-danger" type="submit">Cancel</button>
					</div>
				</CardContent>
			</Card>
		</div>
	)
}

export default ProfileCard