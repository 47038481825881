import { useNavigate } from "react-router";
import "./Index.css";
import Footer from "../Footer/Footer";
const CheckEmail = () => {
	const navigate = useNavigate();

	const handleResetPassword = () => {
		navigate("/reset-password");
	};

	return (
		<div style={{ background: "#220B21" }}>
			<div
				style={{
					position: "relative",
					minHeight: "100vh",
				}}
				className="h-100 w-100"
			>
				<div className="custom-bg w-100 mx-auto">
					<div className="centered-container">
						<img
							src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706140890/AfroWatch/forgot-password/Afrowatch-Logo--Vertical-White-font_umkurt.svg"
							alt="logo"
							className="mb-4 d-block m-auto"
						/>
						<h5 className="text-white  mb-4 mt-4">
                            Check Your Email
						</h5>
						<div>
							<span className="d-block mb-5 mt-3 fs-66">
                                We have sent the password recovery
                                instruction to your email.
							</span>

							<div className="d-flex justify-content-center">
								<span
									className="d-block text-success m-auto mb-5 mt-3 fs-66"
									style={{ cursor: "pointer" }}
									onClick={handleResetPassword}
								>
                                    Skip, I’ll confirm later
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer
				style={{ position: "absolute", bottom: 0, width: "100%" }}
			/>
		</div>
	);
};

export default CheckEmail;
