import React, {useState, useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import NavBarSearch from '../Nav/TopNavBarV2';
import Footer from '../Footer/Footer';
import BottomNav from '../Nav/BottomNav';

const NotFound=()=>{
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});

	return(
		<div className="bg-moviesPage" style={{backgroundColor:'#241423', height:'100vh'}}>
			<NavBarSearch ></NavBarSearch>
			<div className="container">
				<div className='row text-center mt-2'>
					<div className="col-md-12">
						<p className='pricing-plan-text mt-2'>Page does not exists</p>
					</div>
				</div>
			</div>
            
		</div>
	)

}

export default NotFound;