import React,{useEffect,useState} from 'react';
import ProfileIcon from './ProfileIcon';
import axios from 'axios'

const EditProfile=(props)=>{
	const currentUser = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");
	const currentProfileId = localStorage.getItem("profile_id");

	const [profileState, setProfile] = useState({firstName:'', lastName:'', email:''});
	const [user_, setUser] = useState()
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const getUser = async()=>{
		const resp = await axios_.get(`/user/${currentUser}`)
		const {data} =  resp.data;
		if(resp.data.success){
			setUser(data)
		}else{
			console.log(resp.data.message)
		}
	}

	const updateProfile= async (e)=>{
		e.preventDefault()
		try{
			let fullName = currentProfile

			const body = {
				fullName: fullName.trim(),
			}

			const resp = await axios_.put(`${currentUser}/user/${currentProfileId}`, body)
			console.log(resp.data);

			if(resp.data.success){
				const {data} = resp.data
				console.log(data);
               
				setProfile({...profileState, firstName:date.profile_name})
				//show alert
			}else{
				console.log(resp.data.message)
			}
		}catch(e){
			console.log(e)
		}
	}


	useEffect(()=>{
		getUser()
	},[])


	return(
		<div className="container">
			{/* <div className="row mt-5"> */}
			{/* <div className="col-md-12">  */}
			{/* <form id="uploadImage" action="" method="post">
                            <label htmlFor="file-input">
                                <img className="profile-picture mr-md-3" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620996222/AfroWatch/Montreal-personal-branding-linkedin-profile-professional-headshot-by-nadia-zheng-800x1000_1_esxdqb.svg" alt=""/>
                                <img className="edit-pencil" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1621110573/AfroWatch/Group_fibd57.svg" alt=""/>
                            </label>
                            <input id="file-input" type="file"/> 
                        </form>*/}
           
			<form onSubmit={updateProfile} action="" method="post">
				<div className="row mt-5 d-flex">
					<div className="col-md-9">
						{
							user_ ?
								(user_.profiles[0].profile_name === currentProfile ?
									user_.profiles.map((profile, pos)=>{
										return <div key={pos}>
											<p>Profile {pos + 1}</p>
											<span className='d-flex'>
												<input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} className="form-control my-md-3" type="text" name="firstName" value={currentProfile ? profile.profile_name : ""}  placeholder="First name" required/>
												{/* <img className='ml-3 mt-2' style={{width:'50px', height:'50px', borderRadius:'10px'}} src={profile.profile_icon} alt=""/> */}
												<ProfileIcon profile_id={profile._id} profile_icon={profile.profile_icon}/>
											</span>
										</div>
									}):
									user_.profiles.map((profile)=>{
										return  <input onChange={(e)=>setProfile({...profileState, firstName:e.target.value})} style={{backgroundColor:'inherit', color:'white'}} className="form-control my-md-3" type="text" name="firstName" defaultValue={currentProfile ? profile.profile_name : ""}  placeholder="First name" required disabled/>
									})
								)
								: null
						}
					</div>
				</div>

				{user_ ?
					(user_.profiles[0].profile_name === currentProfile ?
						<div className="row">
							<div className="col-md-12">
								<button className="edit-prof-btn py-1 px-5 update-prof-button" type="submit">Update</button>
							</div>
						</div> : null
					) 
					: null
				}
			</form>
			{/* </div> */}
			{/* </div> */}
		</div>
	)

}

export default EditProfile;