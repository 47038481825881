import React, { useEffect, useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4'
import axios from 'axios';
import './SignUp.css';
import { Alert } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import {Slide} from '@mui/material'
import Footer from '../Footer/Footer';

const SignUpPage=()=>{
	const error ="Your password must contain at least 8 characters\na number \na special character !@#$%^_&*\nan upper case letter and a lower case letter"
	const[initialFormState, setFormState] = useState({email:"", password:"", fullName:"",city:'', country:'', state:'', postcode:'', lineOne:'',success:false, isValid:true, error:error});
	const[initialState ,setState] = useState({isAlert:false, signUpSuccess:false, message:""});
	const [showPassword, setShowPassword] = React.useState(false);
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})

	const navigate = useNavigate();
	const regex = /^(?=.*[0-9])(?=.*[!@#$%^_&*])[a-zA-Z0-9!@#$%^_&*]{8,16}/;
	const exists = initialFormState.password.match(regex);
	let resp = Object;

	const location = useLocation()

	const onFormSubmit = async (e)=>{
		e.preventDefault();

		console.log(exists);

		if(exists === null){
			setFormState({...initialFormState, isValid:false});
		}else{
			setFormState({...initialFormState, isValid:true});

			const user = {
				email:initialFormState.email, 
				password: initialFormState.password, 
				fullName: initialFormState.fullName, 
				city:initialFormState.city, 
				country:initialFormState.country, 
				state:initialFormState.state,
				postcode:initialFormState.postcode, 
				lineOne:initialFormState.lineOne
			}

			try{
				resp =  await axios_.post('/auth/signup', user);
				console.log(resp.data.message)
				console.log(resp.data);

				if(resp.data.success){
					setState({...initialState, isAlert:true, signUpSuccess:resp.data.success, message:resp.data.message});
					localStorage.setItem('now', resp.data.data._id)
				}else{
					setState({...initialState, isAlert:true, signUpSuccess:resp.data.success, message:resp.data.message});
				}

				setFormState({...initialFormState, email:"", password:"", fullName:"",city:'', country:'', state:'', postcode:'', lineOne:''})

			}catch(e){
				console.log(e)
				setState({...initialState, isAlert:true, signUpSuccess:false, message:e.response.data.message});
				setFormState({...initialFormState, email:"", password:"", fullName:"",city:'', country:'', state:'', postcode:'', lineOne:''})
			}

		}

		ReactGA.event({
			category: 'Authentication/SignUp',
			action: 'Clicked Submit to create account'
		});
	}

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword =()=>{}
	const toastMessage=()=>{
		toast(`${initialState.message} please check your email for verification`, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true,progress: undefined, pauseOnHover:true})
	}

	useEffect(()=>{
		//just here for launch, incase user gets to sign up route
		//navigate('/')
		//just here for launch, incase user gets to sign up route
		if(location.state?.email !== ""){
			let email_ = location.state?.email
			let fullName_ = location.state?.firstName.trim()+" "+location.state?.lastName.trim()
			setFormState({...initialFormState, email:email_, fullName:fullName_})
		}

		if(location.state == null){
			setFormState({...initialFormState, email:'', fullName:''})
		}
	},[])

	const windowWidth = $(window).width();
    
	const socialSignUp =(e)=>{
		e.preventDefault()
		alert('Coming soon!')
	}

	return(
		<div className="signup-bg">
			<div className="container">
				<div className="alert-row row justify-content-end">
					<div className="col-md-4">
						{initialState.signUpSuccess && initialState.isAlert ? 
							<Slide direction="left" in={true} mountOnEnter unmountOnExit>
								<Alert variant="success" onClose={()=> setState({...initialState, isAlert:false})} dismissible>{`${initialState.message}. please check your email for verification`}</Alert> 
							</Slide> : null}   
                        
                            
						{ initialState.isAlert ?    
							initialState.signUpSuccess ? 
								<Slide direction="left" in={true} mountOnEnter unmountOnExit>
									<Alert style={{zIndex: '1'}} variant="success" onClose={()=> setState({...initialState, isAlert:false})} dismissible>After verification, please login here, <Link to="/login">Login</Link></Alert> 
								</Slide> 
                            
								: 
								<Slide direction="left" in={true} mountOnEnter unmountOnExit>
									<Alert variant="danger" onClose={()=> setState({...initialState, isAlert:false})} dismissible>{initialState.message}</Alert>
								</Slide> : null}
					</div>
				</div>
				{
					windowWidth > 600 ?
						<div className="row justify-content-center">
							<div className="col-md-6 justify-content-center">
								<img className="signup-afrowatch-logo" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
								<h1 className="text-left heading pt-5">Create Account</h1>
                            
								<form onSubmit={onFormSubmit} action="/auth/signup" method="post">
									<div className="form-input">
										<input name="fullName" type="text" className="form-control my-md-5 py-2 sign-up-input" value={initialFormState.fullName} onChange={(e)=>setFormState({...initialFormState,fullName:e.target.value})} placeholder="Full Name"/>
										<input name="email" type="email" className="form-control my-md-5 py-2 sign-up-input" value={initialFormState.email} onChange={(e)=>setFormState({...initialFormState,email:e.target.value})} placeholder="Email Address"/>
										<input name="password" type="password" className="form-control my-md-5 py-2 sign-up-input" value={initialFormState.password} onChange={(e)=>{setFormState({...initialFormState, password: e.target.value})}} placeholder="Password"/>

										<div className="row">
											<div className="col-md-6">
												<input name="country" type="text" className="form-control sign-up-input" value={initialFormState.country} onChange={(e)=>setFormState({...initialFormState,country:e.target.value})} placeholder="Country"/>

												<input name="address" type="text" className="form-control mt-5 sign-up-input" value={initialFormState.lineOne} onChange={(e)=>setFormState({...initialFormState,lineOne:e.target.value})} placeholder="Address"/>
											</div>

											<div className="col-md-6">
												<input name="city" type="text" className="form-control sign-up-input" value={initialFormState.city} onChange={(e)=>setFormState({...initialFormState,city:e.target.value})} placeholder="City"/>

												<input name="postcode" type="text" className="form-control mt-5 sign-up-input" value={initialFormState.postcode} onChange={(e)=>setFormState({...initialFormState,postcode:e.target.value})} placeholder="Postcode"/>
											</div>
										</div>

										<button className="sign-up-btn py-1 px-5 mt-5" type="submit">Sign Up</button>
										{!initialFormState.isValid ? <div className="signup_error">{initialFormState.error}</div> : null}

										<p className="text-center my-3 sign_up_with_email">Or log in with</p>

										<div className="d-flex justify-content-center">
											<a onClick={socialSignUp} href="#"><img className="px-2 signup-social-link" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618765128/AfroWatch/Vector_2_yrfbkq.svg" alt=""/></a>
											<a onClick={socialSignUp} href="#"><img className="px-2 signup-social-link" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618765128/AfroWatch/instagram_logo_frufem.svg" alt="" srcSet=""/></a>
										</div>

										<Link className="signuppage_log-in-link py-1 px-5 mt-3" to="/login">Login</Link>
                                    
									</div>
								</form>
							</div>
						</div>
						:
						<div className="row justify-content-center">
							<div className="col-md-6 justify-content-center">
								<img className="signup-afrowatch-logo_mobile" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt=""/>
								<h1 className="text-left heading pt-5">Create Account</h1>
                            
								<form onSubmit={onFormSubmit} action="/auth/signup" method="post">
									<div className="form-input">
										<input name="fullName" type="text" className="form-control my-5 py-2 sign-up-input" value={initialFormState.fullName} onChange={(e)=>setFormState({...initialFormState,fullName:e.target.value})} placeholder="Full Name"/>
										<input name="email" type="email" className="form-control my-5 py-2 sign-up-input" value={initialFormState.email} onChange={(e)=>setFormState({...initialFormState,email:e.target.value})} placeholder="Email Address"/>
										<input name="password" type="password" className="form-control my-5 py-2 sign-up-input" value={initialFormState.password} onChange={(e)=>{setFormState({...initialFormState, password: e.target.value})}} placeholder="Password"/>

										<div className="row">
											<div className="col-md-6">
												<input name="country" type="text" className="form-control sign-up-input" value={initialFormState.country} onChange={(e)=>setFormState({...initialFormState,country:e.target.value})} placeholder="Country"/>

												<input name="address" type="text" className="form-control mt-5 sign-up-input" value={initialFormState.lineOne} onChange={(e)=>setFormState({...initialFormState,lineOne:e.target.value})} placeholder="Address"/>
											</div>

											<div className="col-md-6">
												<input name="city" type="text" className="form-control mt-5 sign-up-input" value={initialFormState.city} onChange={(e)=>setFormState({...initialFormState,city:e.target.value})} placeholder="City"/>

												<input name="postcode" type="text" className="form-control mt-5 sign-up-input" value={initialFormState.postcode} onChange={(e)=>setFormState({...initialFormState,postcode:e.target.value})} placeholder="Postcode"/>
											</div>
										</div>

										<button className="sign-up-btn_signuppage_mobile py-1 px-5 mt-5" type="submit">Sign Up</button>
										{!initialFormState.isValid ? <div className="signup_error">{initialFormState.error}</div> : null}

										<p className="text-center my-3 sign_up_with_email">Or log in with</p>

										<div className="d-flex justify-content-center">
											<a onClick={socialSignUp} href="#"><img className="px-2 signup-social-link" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618765128/AfroWatch/Vector_2_yrfbkq.svg" alt=""/></a>
											<a onClick={socialSignUp} href="#"><img className="px-2 signup-social-link" src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618765128/AfroWatch/instagram_logo_frufem.svg" alt="" srcSet=""/></a>
										</div>

										<Link className="signuppage_log-in-link py-1 px-5 mt-3" to="/login">Login</Link>
									</div>
								</form>
							</div>
						</div>
				}    
			</div>
			{windowWidth > 600 ? <Footer/> : null}
		</div>
	)
}

export default SignUpPage