import React, {useState, useEffect} from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { axiosInstance } from '../../apiClient';
import { Card } from '@mui/material';


const ExplorePlans=({ user, userId })=>{
	const [plans, setPlan] = useState([])
	const [transactions, setTransactions] = useState();
	const [selectedPlan, setSelectedPlan] = useState();

	let defaultRadioPlan

	const getPlans= async ()=>{
		const res = await axiosInstance.get('/plans')
		if(res.status === 200){
			console.log(res.data)
			setPlan(res.data.plans)

			try{
				for(const plan of res.data.plans){
					if(plan.stripe_price_id === user.currentPlan.priceId){
						defaultRadioPlan = plan.subscription_name
					}
				}
			}catch(e){
				console.log('current plan is null')
			}
		}
	}

	const getTransactionHistory= async ()=>{
		const res = await axiosInstance.get(`/${userId}/stripe/transactions`)
		if(res.status === 200){
			setTransactions(res.data.transactions)
		}
	}


	useEffect(()=>{
		getPlans()
		getTransactionHistory()
	},[])

	return(
		<div className='row'>
			<div className="col-md-6">
				<FormControl>
					<RadioGroup className='mt-3 mt-lg-5 pl-4' aria-labelledby="demo-radio-buttons-group-label" defaultValue={defaultRadioPlan} name="radio-buttons-group">
						{
							plans ? plans.map((plan)=>{
								return <Card key={plan.subscription_name} className='plan-card my-2 px-3'>
									<FormControlLabel value={plan.subscription_name} control={<Radio />} label={plan.subscription_tier.substring(0, 1).toUpperCase() + plan.subscription_tier.substring(1).toLowerCase()} />
									
									{ user && user.currentPlan !== null ?
										plan.stripe_price_id === user.currentPlan.priceId ? <div className='ml-3' style={{color:'#C170C1', fontSize:'14px', fontWeight:'500'}}>Current plan</div> : null : null
									}

									<div className="price-pr-month">
										<div>{`$${plan.price}`}</div>
										<div style={{color:'#A4A4A4'}}>per month</div>
									</div>
								</Card>
							}) : null
						}
					</RadioGroup>
				</FormControl>
			</div>

			<div className="col-md-1">
				<div className="mt-5" style={{backgroundColor:'#C92941', width:'1px', height:'400px'}}></div>
			</div>

			<div className="col-md-5">
				<p className='mt-5'>Transaction History</p>
				{
					transactions ? transactions.map((t)=>{
						return <div key={t.description}>
							<p>{t.description}</p>

							<span className='price-transaction-position'>
								<p>${t.amount/100}</p>
								<p>{new Date(t.created * 1000).toDateString()}</p>
							</span>
							<hr />
						</div>
					}) : null
				}
			</div>
		</div>
	)
}

export default ExplorePlans;