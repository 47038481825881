export function getOrCreatePosterThumbnail(streamData) {

	if (streamData.stream.poster_thumbnail !== null && streamData.stream.poster_thumbnail !== undefined) {
		return streamData.stream.poster_thumbnail
	} else {
		if (streamData.stream.thumbnails_path !== null && streamData.stream.thumbnails_path.length > 0) {
			let poster_id = Math.floor(streamData.stream.thumbnails_path.length / 2)
			return streamData.stream.thumbnails_path[poster_id]
		}
	}
	return null
}