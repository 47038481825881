/* eslint-disable indent */
import React from "react";

export const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
            style={{
                backgroundColor: "purple",
                borderRadius: "5px",
                padding: '5px',
                cursor: 'pointer',
                position: 'absolute',
                top: '-10%',
                right: '2%',
                transform: 'translateY(-50%)',
                width: '32px',
                height: '32px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <svg className="arrow-next" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="#fff" />
                <path fill="none" d="M0 0h24v24H0z" />
            </svg>
        </div>
    );
};
