import React from "react";
import $ from 'jquery'

const PlayButton =()=>{

	const windowWidth = $(window).width()

	return(
		<img style={{
			position: "absolute",
			left: "49%",
			top: "50%",
			width: windowWidth > 600 ? "70px" : "50px",
			height: windowWidth > 600 ? "70px" : "60px",
			transform: "translate(-50%, -50%)"
		}}
			
		src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620946412/AfroWatch/Component_3_zf2imv.svg"
		/>
	)
}

export default PlayButton;